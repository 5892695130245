
import { Avatar, Box, Card, CardActionArea, Chip, List, ListItem, ListItemAvatar, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import AdminUserService from '../services/admin-user-service';

const UsersList = ({ userRoleFilter }) => {
  const adminUserService = new AdminUserService();

  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (searchValue) {
      setFilterUsers(users.filter(user => user.roles.includes(userRoleFilter) && (user.email.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || user.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 || user.id.toString().indexOf(searchValue) !== -1)))
    } else {
      setFilterUsers(users.filter(user => user.roles.includes(userRoleFilter)));
    }
  }, [searchValue, userRoleFilter])

  const getUsers = () => {
    adminUserService.getUsers()
      .then((response) => {
        let usersArray = [];
        for (let user of response) {
          user.initials = getInitials(user.name);
          usersArray.push(user);
        }
        setUsers(usersArray);
        setFilterUsers(usersArray);
      })
      .catch(err => {
        console.log(err);
      })
  }

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  useEffect(() => {
    getUsers();
  }, [])

  return (
    <Box>

      <Stack direction={'row'} sx={{ m: 2, alignItems: 'center' }}>
        <Typography variant="subtitle1" color="text.primary" sx={{ mr: 3 }}>
          Search:
        </Typography>

        <TextField
          id="background-image-url"
          variant="outlined"
          size="small"
          placeholder='Search by Email, Name or ID'
          onChange={e => setSearchValue(e.target.value)}
          value={searchValue || ''}
          sx={{ width: '100%' }}
        />
      </Stack>
      {filterUsers && filterUsers.map((user, index) => {
        return <Card
          sx={{ alignItems: 'center', justifyContent: 'center', mb: 1, borderRadius: '10px' }}
          key={index}
          variant='outlined'
        >
          <CardActionArea
            sx={{ p: 2 }}
            component={Link}
            to={`/admin/users/${user.id}`}
          >
            <List sx={{ p: 0 }}>
              <ListItem sx={{ p: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {user.initials}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={user.name}
                  secondary={<>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      Email:&nbsp;
                    </Typography>
                    {user.email}
                  </>
                  }
                />
                <Chip
                  edge="end"
                  label={user.name}
                  variant='outlined'
                  sx={{ mr: 1 }}
                />
                {userRoleFilter !== 'BUYER' && <Chip
                  edge="end"
                  label={user.approved_for_sale ? 'Approved' : 'Pending'}
                  variant='outlined'
                  color={!user.approved_for_sale ? 'info' : 'primary'}
                />}
              </ListItem>
            </List>
          </CardActionArea>
        </Card>
      })}
    </Box>
  );
};

export default UsersList
