/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Card, CardActionArea, Container, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import AdminEventService from '../../services/event-service';
import moment from 'moment';
import { CalendarMonth, DeleteOutlined, NewspaperRounded } from '@mui/icons-material';
import { DialogAddNewEvent } from '../../components/dialogs/dialog-add-new-event';

export const NewsAndEvents = () => {

  const adminEventService = new AdminEventService();

  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [dialogActive, setDialogActive] = useState(false);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);

    getEvents();

  }, []);

  const getEvents = () => {
    adminEventService.getAllEvents()
      .then(response => {
        setNews(response.filter(item => item.event_type === 'news'));
        setEvents(response.filter(item => item.event_type === 'event'));
      });
  };

  const deleteEvent = (eventId) => {
    adminEventService.deleteEvent(eventId)
      .then(() => {
        getEvents();
      });
  };

  const closeDialog = () => {
    setDialogActive(false);
    getEvents();
  };

  const openDialog = () => {
    setDialogActive(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          News & Events
        </Typography>

        <Button
          variant="outlined"
          size="medium"
          disableElevation
          className="removeUppercase"
          onClick={() => openDialog()}
        >
          Create new event
        </Button>

      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />

      <Box sx={{ mt: 2, pl: 0, pr: 0 }}>
        {events.length > 0 && <Box sx={{ mt: 4, mb: 6 }}>
          <Typography variant='subtitle1' sx={{ pt: 1, pb: 2 }}>
            Events where LINC will feature:
          </Typography>
          {events.map(obj => {
            return <Paper variant='outlined' sx={{ borderRadius: '10px', mt: 2, mb: 2 }} key={obj.title}>
              <List sx={{ padding: 2 }}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon>
                    <CalendarMonth />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.title}
                    secondary={obj.display_date || moment(obj.date).format('dd/MM/yyyy')}
                    sx={{ flexBasis: '30%' }}
                  />

                  <ListItemText
                    primary={`Link URL: ${obj.link_url}`}
                    secondary={`Ordering date: ${moment(obj.date).format('DD MMMM yyyy')}`}
                    sx={{ flexBasis: '70%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                  />

                  <IconButton
                    onClick={() => deleteEvent(obj.id)}
                    color='error'
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteOutlined />
                  </IconButton>
                </ListItem>
              </List>
            </Paper>;
          })}
        </Box>}

        {news.length > 0 && <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant='subtitle1' sx={{ pt: 1, pb: 2 }}>
            News and press releases:
          </Typography>
          {news.map(obj => {
            return <Paper variant='outlined' sx={{ borderRadius: '10px', mt: 2, mb: 2 }} key={obj.title}>
              <List sx={{ padding: 2 }}>
                <ListItem sx={{ p: 0 }}>
                  <ListItemIcon>
                    <NewspaperRounded />
                  </ListItemIcon>
                  <ListItemText
                    primary={obj.title}
                    secondary={obj.display_date || moment(obj.date).format('DD MMMM yyyy')}
                    sx={{ flexBasis: '30%' }}
                  />

                  <ListItemText
                    primary={`Link URL: ${obj.link_url}`}
                    secondary={`Ordering date: ${moment(obj.date).format('DD MMMM yyyy')}`}
                    sx={{ flexBasis: '70%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                  />

                  <IconButton
                    onClick={() => deleteEvent(obj.id)}
                    color='error'
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteOutlined />
                  </IconButton>
                </ListItem>
              </List>
            </Paper>;
          })}
        </Box>}
      </Box>

      <DialogAddNewEvent onCancel={() => closeDialog()} onUpdate={() => closeDialog()} active={dialogActive}></DialogAddNewEvent>
    </Box>
  );
};
