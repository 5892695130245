import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { GaiaTextField } from '../../../shared/components/form-elements/text-field';
import { MenuItem, Select } from '@mui/material';
import AdminEventService from '../../services/event-service';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { locale } from 'moment';

export const DialogAddNewEvent = ({ active, inputEvent, onUpdate, onCancel }) => {

  const adminEventService = new AdminEventService();

  const [locale, setLocale] = useState('en-GB');
  const [event, setEvent] = useState({
    title: '',
    date: '',
    display_date: '',
    link_url: '',
    event_type: ''
  });

  useEffect(() => {
    if (inputEvent) {
      setEvent(inputEvent);
    } else {
      setEvent({
        title: '',
        date: '',
        display_date: '',
        link_url: '',
        event_type: ''
      });
    }
  }, [active]);

  // Set the required investment project data.
  function handleEventUpdate(formControl, value) {
    const updated = event;
    updated[formControl] = value;

    console.log(updated);
    setEvent({ ...updated });
  }

  const handleAddEvent = () => {
    if (event.title && event.date && event.event_type) {
      adminEventService.createEvent(event)
        .then(() => {
          onUpdate();
        });
    }
  };

  return (<>
    {event && <Dialog open={active} onClose={onUpdate}>
      <DialogTitle sx={{ pb: '5px' }}>Create a new event or news item</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ pb: '10px' }}>
          Please provide the details of this event below.
        </DialogContentText>
        <Box
          component="form"
          noValidate
          autoComplete="off"
        >

          <FormControl size="small" >
            <InputLabel id="project-status-label">Event type</InputLabel>
            <Select
              labelId="project-status-label"
              id="project-status"
              value={event.event_type || ''}
              label="Project Status"
              onChange={e => handleEventUpdate('event_type', e.target.value)}
              sx={{ minWidth: '200px' }}
            >
              <MenuItem value={'event'}>Event</MenuItem>
              <MenuItem value={'news'}>News / press release</MenuItem>
            </Select>
          </FormControl>

          <GaiaTextField
            label="Title"
            value={event.title || ''}
            placeholder="The title of the event"
            formControlName='title'
            onChange={handleEventUpdate}>
          </GaiaTextField>

          <GaiaTextField
            label="Display date"
            value={event.display_date || ''}
            placeholder="Enter the date as you want it to be displayed to the user"
            formControlName='display_date'
            onChange={handleEventUpdate}>
          </GaiaTextField>

          <GaiaTextField
            label="Link URL"
            value={event.link_url || ''}
            placeholder="The full URL to send the user to"
            formControlName='link_url'
            onChange={handleEventUpdate}>
          </GaiaTextField>

          <FormControl fullWidth margin='normal' sx={{ mb: 3 }}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
              {event.date && <DatePicker
                label="Date (Used for display order)"
                emptyLabel="DD/MM/YYYY"
                value={moment(event.date)}
                onChange={value => handleEventUpdate('date', value)}
              />}

              {!event.date && <DatePicker
                label="Date (Used for display order)"
                emptyLabel="DD/MM/YYYY"
                onChange={value => handleEventUpdate('date', value)}
              />}
            </LocalizationProvider>
          </FormControl>

        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
        <Button onClick={onCancel} className="removeUppercase">Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleAddEvent}
          className="removeUppercase"
        >
          Add Event
        </Button>
      </DialogActions>
    </Dialog>}
  </>
  );
};
