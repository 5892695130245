import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../../../shared/components/title';
import { Alert, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import moment from 'moment';
import SellerInvestmentProjectService from '../../services/seller-investment-project-service';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function InvestmentProjectsTable() {

  const sellerInvestmentProjectService = new SellerInvestmentProjectService();

  const [investmentProjectsLoading, setInvestmentProjectsLoading] = useState(0);
  const [investmentProjects, setInvestmentProjects] = useState([]);
  const [noInvestmentProjects, setNoInvestmentProjects] = useState(false);
  const [projectStatusFilter, setProjectStatusFilter] = useState('ALL');

  const navigate = useNavigate();

  const setSelectedRow = (investmentProject) => {
    navigate(`/investment-project/${investmentProject.id}`, {
      state: { investmentProject }
    })
  }

  // get projects

  const getInvestmentProjects = async () => {
    setInvestmentProjectsLoading(true);
    sellerInvestmentProjectService.getAllInvestmentProjects()
      .then(response => {
        if (response.investmentProjects.length === 0) {
          setNoInvestmentProjects(true);
        } else {
          setInvestmentProjects(response.investmentProjects);
        }

        setInvestmentProjectsLoading(false);
      })
  };

  useEffect(() => {
    getInvestmentProjects();
  }, []);

  return (
    <>
      {noInvestmentProjects && (
        <Alert severity="info" sx={{ p: 2, m: 8 }}>No investment projects have been created yet.</Alert>
      )}
      {!noInvestmentProjects && (<>
        <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center', pt: 2 }}>
          <Grid item xs={12} md={6} lg={6} sx={{ pt: '0px !important' }}>
            <Title>Investment Projects</Title>
          </Grid>
          <Grid item xs={12} md={6} lg={6} sx={{ pt: '0px !important' }}>
            <Stack direction='row' spacing={3} sx={{ justifyContent: "flex-end", ml: "auto", mt: 0, float: "right", alignItems: 'center' }}>
              <Button
                size="medium"
                disableElevation
                startIcon={<AddCircleOutlineIcon />}
                className="removeUppercase"
                sx={{ mb: 1, float: 'right', mr: 0, textTransform: 'none' }}
                onClick={() => navigate('/investment-project/new')}
              >
                New Project for Funding
              </Button>
              <FormControl size="small" >
                <InputLabel id="project-status-label">Project Status</InputLabel>
                <Select
                  labelId="project-status-label"
                  id="project-status"
                  value={projectStatusFilter}
                  label="Project Status"
                  onChange={(e) => setProjectStatusFilter(e.target.value)}
                  sx={{ minWidth: '200px' }}
                >
                  <MenuItem value={'ALL'}>All Projects</MenuItem>
                  <MenuItem value={'DRAFT'}>Draft</MenuItem>
                  <MenuItem value={'PUBLISHED'}>Published</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow sx={{ fontWeight: 600 }}>
              <TableCell sx={{ fontWeight: 600, pl: 0, width: '40px' }}></TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Title</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Subtitle</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Date created</TableCell>
              <TableCell sx={{ fontWeight: 600 }}>Approval Status</TableCell>
              <TableCell align='right' sx={{ fontWeight: 600 }}>Funding Requirement</TableCell>
              <TableCell align='right' sx={{ fontWeight: 600 }}>Target Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(investmentProjectsLoading ? Array.from(new Array(10)) : investmentProjects?.filter(proj => projectStatusFilter === 'ALL' ? proj : proj.admin_status.status_code === projectStatusFilter))?.map((investmentProject, index) => (
              investmentProject ? (
                <TableRow key={investmentProject.id} onClick={() => setSelectedRow(investmentProject)} hover={true}>
                  <TableCell>
                    {investmentProject.admin_status.status_code === 'PUBLISHED' && <TaskAltIcon color='success' sx={{ verticalAlign: 'middle' }} />}
                    {investmentProject.admin_status.status_code === 'DRAFT' && <PauseCircleOutlineIcon color='info' sx={{ verticalAlign: 'middle' }} />}
                    {investmentProject.admin_status.status_code !== 'DRAFT' && investmentProject.admin_status.status_code !== 'PUBLISHED' && <TaskAltIcon sx={{ verticalAlign: 'middle', color: 'text.secondary' }} />}
                  </TableCell>
                  <TableCell>{investmentProject.title_text}</TableCell>
                  <TableCell>{investmentProject.subtitle_text}</TableCell>
                  <TableCell>{investmentProject?.created_at ? moment(investmentProject?.created_at).format('DD/MM/yyyy') : 'tbc'}</TableCell>

                  <TableCell>
                    <Chip
                      label={investmentProject.admin_status.status_name}
                      variant="outlined"
                      sx={{
                        justifyContent: "center",
                        color: investmentProject.admin_status.color || "primary",
                        borderColor: investmentProject.admin_status.color || "primary"
                      }}
                    />
                  </TableCell>
                  <TableCell align='right'><NumericFormat value={investmentProject.investment_target} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} /></TableCell>
                  <TableCell align='right'>{investmentProject?.investment_target_date ? moment(investmentProject?.investment_target_date).format('DD/MM/yyyy') : 'tbc'}</TableCell>
                </TableRow>
              ) : (
                <TableRow key={index}>
                  <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" variant="text" />
                  </TableCell>
                </TableRow>
              )))
            }
          </TableBody>
        </Table>
      </>
      )}
    </>
  );
}
