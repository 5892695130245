import React from "react";
import { FileUploader } from "react-drag-drop-files";
import Compressor from 'compressorjs';

const FileUpload = (
  props
) => {

  const handleCompressedUpload = (e) => {
    if (props.pdf || props.downloadableResource) {
      props.handleFileUpload(e)
    } else {
      const image = e;
      new Compressor(image, {
        maxWidth: 1600,
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          props.handleFileUpload(compressedResult);
          //setCompressedFile(res)
        },
      });
    }

  };

  let fileTypes = props.pdf ? ["PDF"] : ["JPG", "PNG", "GIF", "JPEG"];

  if (props.downloadableResource) {
    fileTypes = ["DOCX", "PPTX"]
  }

  return (
    <FileUploader
      handleChange={(event) => handleCompressedUpload(event)}
      name="file"
      types={fileTypes}
      classes='file-upload'
      maxSize={props.maxSize ? props.maxSize : 20}
    />
  );
}

export default FileUpload;