/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import TeamForm from '../../components/config/team/team-form';

const Team = () => {

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ p: 2 }}>
      <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
        <Typography variant="h5" color="text.primary" sx={{ flex: 1, lineHeight: '40px' }}>
          Team
        </Typography>
      </Stack>
      <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
      <TeamForm />
    </Box>
  );
};

export default Team 