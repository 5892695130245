import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Chip, Container, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Paper, Stack, TextField, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { Link, useParams } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import AdminUserService from '../services/admin-user-service';
import AnswerFormDisplay from '../../shared/components/answer-form-display';
import LoadingSpinner from '../../shared/components/loading-spinner/loading-spinner';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconPicker from '../../shared/components/icon-picker';
import { EditUserDetailsForm } from '../components/edit-user-details-form';
import AdminProjectsTable from '../components/projects-table';
import AdminInvestmentProjectsTable from '../components/investment-projects-table';

const AdminUserDetail = () => {

  const [user, setUser] = useState();
  const [expanded, setExpanded] = useState(false);

  const adminUserService = new AdminUserService();
  const params = useParams();

  const getUser = () => {
    adminUserService.getSeller(params.id)
      .then(response => {
        setUser(response)
      })
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleToggleUserApproval = () => {
    adminUserService.toggleUserApproval(user.id)
      .then(() => {
        getUser()
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.analytics.page();
  }, [])

  return (
    <LoadingSpinner isLoading={!user} loadingMessage='Loading user details'>

      <Toolbar />
      {user && <Container maxWidth={false} sx={{ mt: 3, mb: 4 }}>
        <Stack direction='row' sx={{ alignItems: 'center', mb: 1 }}>
          <Box sx={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            <Link
              component="button"
              variant="body2"
              to={'/admin/users'}
              underline='hover'
              style={{ ml: '-7px', color: '#70942c' }}
            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back
                </Typography>
              </Grid>
            </Link>
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <Paper variant='outlined' sx={{ padding: 4, height: '100%', borderRadius: '10px' }}>

              {user?.completion_answer_forms.length > 0 && <Typography variant="h6" color="text.primary" sx={{ mt: 2, mb: 2, whiteSpace: 'pre-wrap' }}>
                User information provided:
              </Typography>}

              {user?.completion_answer_forms?.map((form, index) => {
                return <Accordion
                  key={index}
                  sx={{ mb: 4 }}
                  expanded={expanded === `panel-${index}`}
                  onChange={handleChange(`panel-${index}`)}>

                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{ alignItems: 'center', justifyContent: 'center' }}

                  >
                    <List sx={{ p: 0 }}>
                      <ListItem sx={{ p: 0 }}>
                        <ListItemIcon>
                          <IconPicker icon="form" color='primary' />
                        </ListItemIcon>
                        <ListItemText primary={form.question_form?.form_name} />
                      </ListItem>
                    </List>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AnswerFormDisplay key={index} suppliedAnswers={form.answers} />
                  </AccordionDetails>
                </Accordion>
              })}
              <EditUserDetailsForm userId={params.id} updateSuccessful={getUser} />
            </Paper>
            {/* 
            {user?.projects?.length > 0 && <Paper sx={{ padding: 2, mt: 2 }}>
              <AdminProjectsTable inputProjects={user.projects} pageTitle={'BNG projects'} />
            </Paper>}

            {user?.investment_projects?.length > 0 && <Paper sx={{ padding: 2, mt: 2 }}>
              <AdminInvestmentProjectsTable inputProjects={user.investment_projects} pageTitle={'Investment projects'} />
            </Paper>} */}
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                borderRadius: '10px',
                mb: 2,
              }}
            >
              <Grid container>
                <Grid item xs={8} md={8} lg={8} sx={{ mb: 2 }}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                    Admin Actions
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4} lg={4} sx={{ mb: 2 }}>
                  <Box>
                    <Chip
                      label={user.approved_for_sale ? 'Approved' : 'Pending'}
                      variant="outlined"
                      sx={{
                        justifyContent: "flex-end",
                        ml: "auto",
                        float: "right",
                        color: "primary",
                        borderColor: "primary"
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mb: 2 }}>
                  <Button variant='contained' disableElevation onClick={() => handleToggleUserApproval()} className='removeUppercase'>{user.approved_for_sale ? 'Disable user' : 'Approve user'}</Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
              }}
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                User Details
              </Typography>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {/* {projectDetails?.owner?.initials} */}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user?.name} secondary={user.email} />
              </ListItem>
            </Paper>

            <Paper
              variant='outlined'
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                borderRadius: '10px'
              }}
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                Organisation:
              </Typography>

              <Typography variant="p" color="text.primary" sx={{ mb: 1 }}>
                {user.organisation}
              </Typography>
            </Paper>
          </Grid>
        </Grid >

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            {user?.projects?.length > 0 && <Paper variant='outlined' sx={{ padding: 4, mt: 2, borderRadius: '10px' }}>
              <AdminProjectsTable inputProjects={user.projects} pageTitle={'BNG projects'} />
            </Paper>}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} >
            {user?.investment_projects?.length > 0 && <Paper variant='outlined' sx={{ padding: 4, mt: 2, borderRadius: '10px' }}>
              <AdminInvestmentProjectsTable inputProjects={user.investment_projects} pageTitle={'Investment projects'} />
            </Paper>}
          </Grid>
        </Grid>

      </Container >}
    </LoadingSpinner>
  );
};

export default AdminUserDetail