import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Grid, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Typography } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import InvestmentProjectBody from '../../shared/components/investment-project/investment-project-body';
import { Link, useNavigate } from 'react-router-dom';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import AdminInvestmentProjectService from '../services/admin-investment-project';
import { ProjectApprovalMessageContainer } from '../../shared/components/project-approval-message-container';
import { AdminActionsBox } from '../components/approvals/admin-actions';
import { CreateInvestmentPledgeButton } from '../components/create-investment-pledge-button';
import PledgeProgressBar from '../../seller/components/pledge-progress-bar';
import { NumericFormat } from 'react-number-format';
import { Autorenew, CloseOutlined, Delete, EditNoteRounded, FileDownloadOutlined } from '@mui/icons-material';
import { DialogChangeShallowStatus } from '../../shared/components/dialogs/dialog-change-shallow-status';
import { UserInformationProviderContext } from '../../shared/context/user-information-provider';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { InvestmentProjectPdf } from './pdf-download/investment-project-pdf';
import { HubDetailsContext } from '../../shared/context/hub-details';

const AdminInvestmentProjectPage = () => {

  const adminInvestmentProjectService = new AdminInvestmentProjectService();
  const userInformationContext = useContext(UserInformationProviderContext);
  const hub = useContext(HubDetailsContext);

  const [projectDetails, setProjectDetails] = useState();
  const [projectImages, setProjectImages] = useState([]);
  const [approval, setApproval] = useState({ outcome: '', comments: '' });
  const [actionSuccess, setActionSuccess] = useState(0)
  const [showShallowStatusDialog, setShowShallowStatusDialog] = useState(false);
  const [hubDetails, setHubDetails] = useState();

  let navigate = useNavigate();

  const getInvestmentProject = async () => {
    const path = window.location.pathname;
    const projectId = path.substring(path.lastIndexOf('/') + 1);

    adminInvestmentProjectService.getInvestmentProject(projectId)
      .then(response => {
        const dateAddedISO = new Date(response.created_at.replace(' ', 'T'));
        const dateAdded = dateAddedISO.toLocaleDateString('en-GB');
        const initials = getInitials(response?.owner?.name);
        const project = { ...response, dateAdded: dateAdded, owner: { ...response.owner, initials: initials } };
        setProjectImages(response.investment_project_images);
        setProjectDetails(project);
      })
  }

  const handleChangeStatus = (selectedStatus) => {
    adminInvestmentProjectService.changeStatus(projectDetails.id, selectedStatus, false)
      .then(() => {
        getInvestmentProject()
      })
  }

  const handleRemoveDecline = (selectedStatus) => {
    adminInvestmentProjectService.removeDecline(projectDetails.id, selectedStatus)
      .then(() => {
        getInvestmentProject()
      })
  }

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const approveInvestmentProject = (sendEmail) => {
    adminInvestmentProjectService.approveInvestmentProject(projectDetails.id, sendEmail, approval.comments)
      .then(() => {
        getInvestmentProject();
        setApproval({ outcome: '', comments: '' });
        setActionSuccess(actionSuccess + 1);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const rejectInvestmentProject = (sendEmail) => {
    adminInvestmentProjectService.rejectInvesmentProject(projectDetails.id, sendEmail, approval.comments)
      .then(() => {
        getInvestmentProject();
        setApproval({ outcome: '', comments: '' });
        setActionSuccess(actionSuccess + 1);
      })
      .catch(err => {
        console.log(err)
      })
  }

  const deleteInvestmentProject = () => {
    userInformationContext.openDialog({
      active: true,
      title: 'Do you want to delete this project?',
      message: 'This action cannot be undone.'
    })
      .then(response => {
        if (response) {
          adminInvestmentProjectService.deleteProject(projectDetails.id)
            .then(() => {
              navigate('/admin/investment-projects')
            })
            .catch(err => {
              console.log(err);
            });
        }
      })
  }

  const handleStatusUpdate = () => {
    setShowShallowStatusDialog(false);
    setHubDetails(hub.hubDetails);
    getInvestmentProject();
  }

  useEffect(() => {
    getInvestmentProject();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.analytics.page();
  }, [])

  return (
    <>
      <Toolbar />

      <Container maxWidth={false} sx={{ mt: 3, mb: 4 }}>
        <Stack direction='row' sx={{ alignItems: 'center', mb: 1 }}>
          <Box sx={{ flex: 1, alignItems: 'center', display: 'flex' }}>
            <Link
              component="button"
              variant="body2"
              onClick={() => navigate(-1)}
              underline='hover'
              style={{ ml: '-7px', color: '#70942c' }}

            >
              <Grid container direction="row" alignItems="center">
                <KeyboardArrowLeft />
                <Typography variant="button" display="block" className='removeUppercase'>
                  Back
                </Typography>
              </Grid>
            </Link>
          </Box>
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={8}>
            <InvestmentProjectBody function='admin' projectImages={projectImages} projectDetails={projectDetails} getInvestmentProject={getInvestmentProject} />
          </Grid>
          <Grid item xs={12} md={4} lg={4} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
                //position: 'sticky',
                //top: '90px',
              }}
              variant='outlined'
            >
              {projectDetails && <AdminActionsBox
                adminStatus={projectDetails.admin_status}
                approval={approval}
                setApproval={setApproval}
                approve={approveInvestmentProject}
                reject={rejectInvestmentProject}
                actionSuccess={actionSuccess}
              ></AdminActionsBox>}


              {projectDetails && projectDetails.admin_status.is_published_status && <Stack direction={'column'} sx={{ mt: 2 }}>
                <Stack direction={'row'}>

                  <Button
                    color={'info'}
                    variant={'outlined'}
                    onClick={() => handleChangeStatus('DRAFT')}
                    className='removeUppercase'
                    startIcon={<EditNoteRounded />}
                    sx={{ flex: 1, mr: 1 }}>
                    Set to draft
                  </Button>
                  <Button
                    color={'error'}
                    variant={'outlined'}
                    onClick={() => handleChangeStatus('CLOSED')}
                    className='removeUppercase'
                    startIcon={<CloseOutlined />}
                    sx={{ flex: 1, ml: 1 }}>
                    Close project
                  </Button>

                </Stack>

              </Stack>}

              {projectDetails && projectDetails.admin_status.status_code === 'AUTO_DECLINE' && <Stack direction={'column'} sx={{ mt: 2 }}>
                <Stack direction={'row'}>

                  <Button
                    color={'info'}
                    variant={'outlined'}
                    onClick={() => handleRemoveDecline('DRAFT')}
                    className='removeUppercase'
                    startIcon={<EditNoteRounded />}
                    sx={{ flex: 1 }}>
                    Remove auto decline
                  </Button>
                </Stack>

              </Stack>}

              <Box sx={{ mt: 2, width: '100%' }}>
                {projectDetails && <Stack direction={'column'} sx={{ mb: 2 }}>
                  <Button
                    color={'info'}
                    variant={'outlined'}
                    onClick={() => setShowShallowStatusDialog(true)}
                    className='removeUppercase'
                    startIcon={<Autorenew />}
                    sx={{ flex: 1 }}>
                    Change status
                  </Button>
                </Stack>}
              </Box>

              <Button
                color={'error'}
                variant={'outlined'}
                onClick={() => deleteInvestmentProject()}
                className='removeUppercase'
                startIcon={<Delete />}>
                Delete project
              </Button>

              <Box sx={{ mt: 2, width: '100%' }}>
                {projectDetails && projectDetails.admin_status.status_code !== 'PENDING_REVIEW' && projectDetails.admin_status.status_code !== 'APPROVED' && <CreateInvestmentPledgeButton projectId={projectDetails.id} onUpdate={getInvestmentProject} />}
              </Box>

              {projectDetails && <Button
                sx={{ mt: 2 }}
                variant='outlined'
                className="removeUppercase"
                startIcon={<FileDownloadOutlined />}
                style={{ textTransform: 'none', color: '#000', borderColor: '#000' }}
              >
                <PDFDownloadLink document={
                  <InvestmentProjectPdf
                    projectDetails={projectDetails}
                    hubDetails={hubDetails}
                  />
                } fileName={projectDetails?.title_text} style={{ textTransform: 'none', color: '#000' }}>
                  Download PDF
                </PDFDownloadLink>
              </Button>}

            </Paper>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: '20vh',
                mb: 2,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Grid container>
                <Grid item xs={12} md={12} lg={4} sx={{ mb: 2 }}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    Summary
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <PledgeProgressBar value={projectDetails ? (projectDetails?.amount_pledged / projectDetails?.investment_target) * 100 : 0} />
                </Grid>
                <Grid item xs={12} md={6} lg={6} sx={{ mt: 1 }}>
                  <Typography variant="h3" color="primary" sx={{ fontWeight: 600 }}>
                    <NumericFormat value={projectDetails?.amount_pledged} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="body2" color="text.secondary">
                    Raised of <NumericFormat value={projectDetails?.investment_target || 0} displayType={'text'} thousandSeparator={true} prefix={'£'} /> target
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                mb: 2,
                borderRadius: '10px'
                //position: 'sticky',
                //top: '265px',
              }}
              variant='outlined'
            >
              <Typography variant="subtitle1" color="text.primary" sx={{ fontWeight: 600, mb: 1 }}>
                User Details
              </Typography>
              <ListItem sx={{ pl: 0 }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    {projectDetails?.owner?.initials}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={projectDetails?.owner?.name} secondary={projectDetails?.owner?.email} />
              </ListItem>
            </Paper>

            {projectDetails && <ProjectApprovalMessageContainer
              sectionTitle='Approval comment history'
              projectComments={projectDetails.investment_project_comments}
              adminStatusCode={projectDetails.admin_status.status_code}>
            </ProjectApprovalMessageContainer>}
          </Grid>
        </Grid >
      </Container >

      {projectDetails && <DialogChangeShallowStatus
        projectId={projectDetails.id}
        statusCode={projectDetails.shallow_status?.status_code}
        active={showShallowStatusDialog}
        onCancel={() => setShowShallowStatusDialog(false)}
        onUpdate={() => handleStatusUpdate()}
        projectType={'INVESTMENT_PROJECT'}
        isAdmin={true}
      />}
    </>
  );
};

export default AdminInvestmentProjectPage