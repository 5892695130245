import { MailOutline, Reply } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import EnquiryDialog from './enquiry-dialog';

export const BuyerEnquiryButton = ({
  projectId,
  enquiryId,
  projectType,
  freeTier,
  onSuccess,
  userRole,
  isReply = false
}) => {

  const navigate = useNavigate();

  const [enquiryFormActive, setenquiryFormActive] = useState(false);

  function goToMessages() {
    console.log(userRole, enquiryId)
    navigate(`/${userRole}/enquiry/${enquiryId}`)
  }

  const handleSuccess = () => {
    setenquiryFormActive(false);
    onSuccess();
  }

  return (<>
    {projectId && !enquiryId && <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      className='removeUppercase'
      startIcon={<MailOutline />}
      onClick={() => setenquiryFormActive(true)}
    // disabled={!canSendEnquiry}

    >
      Contact Seller
    </Button>}

    {projectId && enquiryId && !isReply && <Button
      variant="contained"
      color="primary"
      size="large"
      disableElevation
      className='removeUppercase'
      startIcon={<MailOutline />}
      onClick={() => goToMessages()}
      disabled={freeTier}
    >
      View messages
    </Button>}

    {projectId && enquiryId && isReply && <Button
      variant="contained"
      color="primary"
      //size="large"
      disableElevation
      className='removeUppercase'
      onClick={() => setenquiryFormActive(true)}
      startIcon={<Reply />}
    >
      Reply
    </Button>}

    <EnquiryDialog
      active={enquiryFormActive}
      projectId={projectId}
      projectType={projectType}
      onCancel={() => setenquiryFormActive(false)}
      onSuccess={() => handleSuccess()}
      isReply={isReply}
      enquiryId={enquiryId}
      userRole={userRole}
    />
  </>)
}