import React, { useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Grid, Paper } from '@mui/material';
import Counter from '../../../shared/components/dashboard/counter'
import httpClient from '../../../shared/http/http-client';
import BarChartWidget from '../../../shared/components/dashboard/bar-chart';
import Title from '../../../shared/components/title';

const Analytics = () => {

  const [widgets, setWidgets] = useState([])
  const [listingsByMonth, setListingsByMonth] = useState([])
  const [usersByRole, setUsersByRole] = useState([])

  // get projects

  const getStatistics = async () => {
    httpClient.get('admin/site-admin/analytics/statistics')
      .then((response) => {
        console.log(response)
        setWidgets(response.data);
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  const getListingsByMonth = async () => {
    httpClient.get('admin/site-admin/analytics/listings-by-month')
      .then((response) => {
        console.log(response.data)
        setListingsByMonth(response.data)
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  const getUsersByRole = async () => {
    httpClient.get('admin/site-admin/analytics/users-by-role')
      .then((response) => {
        setUsersByRole(response.data)
      })
      .catch(err => {
        console.error(err.message);
      })
  };

  useEffect(() => {
    getListingsByMonth();
    getUsersByRole();
  }, []);

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Counter
                  title={usersByRole[0]?.name}
                  value={usersByRole[0]?.value}
                  viewLabel={usersByRole[0]?.name}
                  noLink
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Counter
                  title={usersByRole[1]?.name}
                  value={usersByRole[1]?.value}
                  viewLabel={usersByRole[1]?.name}
                  noLink
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 400,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Title>Listings by Month</Title>
              <BarChartWidget data1={listingsByMonth} xDataKey="month_created" barDataKey="total_listings" barName='Total Listings' />
            </Paper>
          </Grid>
          {widgets?.sort((a, b) => (a.id > b.id) ? 1 : -1).map((widget) => {
            return <Grid item xs={12} md={4} lg={4}>
              <Counter
                title={widget.label}
                value={widget.value}
                viewLabel={widget.label}
                type={widget.type}
                noLink
              />
            </Grid>
          })}
        </Grid>
      </Container>
    </>
  );
};

export default Analytics