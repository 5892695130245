import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Grid, Typography } from '@mui/material';
import httpClient from '../../../../shared/http/http-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { UserDetailsContext } from '../../../../shared/context/user-details';
import BlockIcon from '@mui/icons-material/Block';

const TeamForm = (props) => {

  const [users, setUsers] = useState();
  const [update, setUpdate] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [disableAgentDialogueActive, setDisableAgentDialogueActive] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState();

  const userContext = useContext(UserDetailsContext);

  const getInitials = (string) => {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getAdminUsers()
  }, [update])

  function getAdminUsers() {
    httpClient.get('admin/hub/admin-users')
      .then(response => {
        let usersArray = [];
        for (let user of response.data) {
          user.initials = getInitials(user.name);
          usersArray.push(user);
        }
        setUsers(usersArray);
      })
  }

  function removeAdminUser(userId) {
    httpClient.post('admin/hub/remove-admin-user', { user_id: userId })
      .then(response => {
        setUpdate(update + 1)
      })
  }

  const handleChange =
    (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

  const showDisableAgentDialogue = (userId) => {
    setAgentToDelete(userId);
    setDisableAgentDialogueActive(!disableAgentDialogueActive);
  };

  const handleDisableAgent = (userId) => {
    removeAdminUser(userId);
    setDisableAgentDialogueActive(false);
  }

  return (
    <>
      <Grid item xs={12} md={12} lg={12}>
        {users && users.sort((a, b) => a.id > b.id ? 1 : -1).map((user, index) => {
          return <Accordion
            key={index}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
            elevation={0}
            sx={{
              border: `1px solid rgba(0, 0, 0, 0.12)`, '&:not(:last-child)': {
                borderBottom: !expanded && 0,
              },
              '&::before': {
                display: 'none',
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              sx={{ alignItems: 'center', justifyContent: 'center' }}

            >
              <List sx={{ p: 0, width: '100%' }}>
                <ListItem
                  sx={{ p: 0 }}
                  secondaryAction={user.id !== userContext.userDetails.id && (
                    <Button
                      onClick={() => showDisableAgentDialogue(user.id)}
                      color="error"
                      variant="text"
                      size="medium"
                      disableElevation
                      className="removeUppercase"
                      startIcon={<BlockIcon />}
                      sx={{
                        visibility: expanded === `panel-${index}` ? "visible" : "hidden"
                      }}
                    >
                      Disable account
                    </Button>
                  )}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: 'primary.main' }}>
                      {user.initials}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.name} secondary={user.email} />
                </ListItem>
              </List>
            </AccordionSummary>
            <AccordionDetails sx={{ borderBottom: expanded && expanded !== `panel-${index}` && '1px solid rgba(0, 0, 0, 0.12)' }}>
              <Grid container spacing={3} sx={{ pl: '56px', pr: '56px', pt: '10px', mb: 4 }}>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography variant="subtitle1" color="text.primary">
                    Username:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                  <Typography variant="subtitle1" color="text.secondary" sx={{ wordBreak: 'break-all' }}>
                    {user.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography variant="subtitle1" color="text.primary">
                    Email:
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                  <Typography variant="subtitle1" color="text.secondary" sx={{ wordBreak: 'break-all' }}>
                    {user.email}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        })}
        <Dialog open={disableAgentDialogueActive} onClose={showDisableAgentDialogue} maxWidth='sm' fullWidth>
          <DialogTitle sx={{ pb: '5px' }}>Delete account</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ pb: '10px' }}>
              Are you sure you want to delete the account?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'flex-end', mr: 2, mb: 2 }}>
            <Button
              onClick={showDisableAgentDialogue}
              className="removeUppercase"
              sx={{ color: "text.primary" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              disableElevation
              onClick={() => handleDisableAgent(agentToDelete)}
              startIcon={<BlockIcon />}
              className="removeUppercase"
            >
              Delete account
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

export default TeamForm 