import React, { useContext, useEffect, useState } from "react";
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ArticleGroupService from '../../../shared/services/article-group-service';
import LearningMenu from '../../../shared/components/articles/learning-menu';
import ArticleCard from '../../../shared/components/articles/article-card';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ArticleService from '../../../shared/services/article-service';
import { UserInformationProviderContext } from '../../../shared/context/user-information-provider';


const AdminArticleList = (props) => {
  const articleGroupService = new ArticleGroupService();
  const articleService = new ArticleService();
  const userInformationContext = useContext(UserInformationProviderContext);

  const [articleGroups, setArticleGroups] = useState([]);
  const [articles, setArticles] = useState([]);
  const [activeGroup, setActiveGroup] = useState();
  const [updated, setUpdated] = useState(0)

  useEffect(() => {
    articleGroupService.getAllArticleGroups()
      .then(response => {
        setArticleGroups(response);
        setArticles(response[0]?.articles);
      })
  }, [updated]);

  useEffect(() => {
    setArticles([]);
    if (activeGroup) {
      articleGroupService.getOneArticleGroup(activeGroup)
        .then(response => {
          setArticles(response.articles);
        })
    }
  }, [activeGroup]);

  const handleDeleteClick = (articleId) => {
    userInformationContext.openDialog({
      active: true,
      title: 'Delete this article?',
      message: 'Are you sure you would like to permanently delete this article?'
    })
      .then(response => {
        if (response) {
          articleService.deleteArticle(articleId)
            .then(() => {
              setUpdated(updated + 1)
            })
        }
      })
  }

  const handleUpdateDisplayOrder = (id, index) => {
    articleService.updateDisplayOrder(id, index)
      .then(() => {
        setUpdated(updated + 1)
      })
  }

  useEffect(() => {
    window.analytics.page();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                position: 'sticky',
                top: '90px',
                minHeight: 'calc(100vh - 130px)',
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <LearningMenu
                routeToArticle={`/admin/articles/`}
                articleGroups={articleGroups}
                activeGroup={activeGroup}
                setActiveGroup={setActiveGroup}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Grid container spacing={2}>
              {articles && articles.map((article, index) => (
                <Grid item xs={12} md={6} lg={6} key={index}>
                  <ArticleCard
                    routeToArticle={`/admin/articles/${article.id}`}
                    article={article}
                    elevated={true}
                    isAdmin={true}
                  />
                  <Paper variant='outlined' sx={{ mt: 1, mb: 1, p: 2, borderRadius: '10px' }}>
                    <Stack direction={'row'} spacing={1}>
                      <FormControl size="small" sx={{ flex: 1 }}>
                        <InputLabel id="user-type-label">Display order</InputLabel>
                        <Select
                          labelId="user-type-label"
                          id="user-type"
                          value={index + 1}
                          label="Display order"
                          onChange={(e) => handleUpdateDisplayOrder(article.id, e.target.value - 1)}
                        >
                          {articles.map((article, rowIndex) => {
                            return <MenuItem key={rowIndex} value={rowIndex + 1}>{rowIndex + 1}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                      <Button
                        variant={'outlined'}
                        color="info"
                        disableElevation
                        startIcon={<Edit />}
                        className='removeUppercase'
                        sx={{ mr: 1, ml: 1 }}
                        component={Link}
                        to={`/admin/articles/edit/${article.id}`}
                      >
                        Edit
                      </Button>

                      <Button
                        variant={'outlined'}
                        //color="error"
                        disableElevation
                        startIcon={<Delete />}
                        className='removeUppercase'
                        onClick={() => handleDeleteClick(article.id)}
                        sx={{
                          color: '#111E21',
                          borderColor: '#111E21',
                        }}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AdminArticleList