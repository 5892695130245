
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import { v4 as uuidv4 } from 'uuid';

export default class CognitoUserAuthenticationService {
  cookieDomain;
  userPool;

  static instance = null;

  static getInstance(cookieDomain) {
    if (CognitoUserAuthenticationService.instance === null) CognitoUserAuthenticationService.instance = new CognitoUserAuthenticationService(cookieDomain);
    if (cookieDomain) {
      this.instance.cookieDomain = cookieDomain;
    }
    return this.instance;
  }

  constructor(cookieDomain = 'gaiacompany.io') {
    this.cookieDomain = `.${cookieDomain}`
    this.userPool = new CognitoUserPool({
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : this.cookieDomain })
    });
  }

  async registerUser(userDetails) {
    return new Promise((resolve, reject) => {

      const attributeList = [
        new CognitoUserAttribute({
          Name: "email",
          Value: userDetails.email
        }),
        new CognitoUserAttribute({
          Name: "name",
          Value: `${userDetails.firstname} ${userDetails.lastname}`
        }),
        new CognitoUserAttribute({
          Name: "given_name",
          Value: userDetails.firstname
        }),
        new CognitoUserAttribute({
          Name: "family_name",
          Value: userDetails.lastname
        }),
        new CognitoUserAttribute({
          Name: "custom:company",
          Value: userDetails.organisation
        }),
        new CognitoUserAttribute({
          Name: "custom:role",
          Value: userDetails.role
        }),
        new CognitoUserAttribute({
          Name: "custom:tenant_id",
          Value: userDetails.role === 'SELLER' ? uuidv4() : 'Buyer'
        }),
        new CognitoUserAttribute({
          Name: "custom:terms_and_conditions",
          Value: new Date()
        }),
        new CognitoUserAttribute({
          Name: "custom:hub_id",
          Value: userDetails.hubId.toString()
        })
      ]

      this.userPool.signUp(userDetails.email, userDetails.password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
          throw err;
        } else {
          resolve(result)
        }
      });
    });
  }

  async confirmRegistration(userDetails, verificationCode) {
    return new Promise((resolve, reject) => {

      var userData = {
        Username: userDetails.email,
        Pool: this.userPool,
        Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : this.cookieDomain })

      };

      var cognitoUser = new CognitoUser(userData);
      cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
        if (err) {
          reject(err)
          throw err;
        }

        resolve(true)
      });
    })
  }

  async loginUser(email, password) {
    return new Promise((resolve, reject) => {

      const authenticationData = {
        Username: email,
        Password: password,
      };

      const authenticationDetails = new AuthenticationDetails(
        authenticationData
      );

      const userData = {
        Username: email,
        Pool: this.userPool,
        Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : this.cookieDomain })

      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve(true)
        },

        onFailure: function (err) {
          reject(err)
          // setAuthenticationError(true);
          // setAuthenticationErrorMessage(err.message);
        },

        mfaRequired: function (err) {
          reject(err)
          // MFA is required to complete user authentication.
          // Get the code from user and call
          console.log('MFA required');
        },
      });
    })
  }


  async sendConfirmationCode(email) {
    return new Promise((resolve, reject) => {

      var userData = {
        Username: email,
        Pool: this.userPool,
        Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : this.cookieDomain })

      };
      var cognitoUser = new CognitoUser(userData);

      return cognitoUser.resendConfirmationCode((err, response) => {
        if (err) {
          reject(err)
        }

        resolve(response)
      });
    })
  }

  async logout(username) {
    var userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : this.cookieDomain })
    };

    var cognitoUser = new CognitoUser(userData);

    return cognitoUser.signOut();
  }

  async getCookie() {
    const poolData = {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      Storage: new CookieStorage({ secure: process.env.REACT_APP_ENVIRONMENT !== 'LOCAL', sameSite: 'strict', domain: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? 'localhost' : this.cookieDomain })
    }
    const userPool = new CognitoUserPool(poolData)

    // var cognitoUser = this.userPool.getCurrentUser();
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      const token = await this.getSession(cognitoUser)
      return await token
    } else {
      return
    }
  }

  async getSession(cognitoUser) {
    return new Promise(function (resolve, reject) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          console.log(err)
          resolve(undefined)
        } else {

          const accessToken = session.getAccessToken();
          resolve(accessToken.getJwtToken())
        }
      })
    })
  }


}