import { Alert, Box, Card, CardActionArea, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Select, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';
import AdminSiteEnquiryFormListService from '../services/admin-site-enquiry-form-list-service';
import gaiaTheme from '../../theme';
import IconPicker from '../../shared/components/icon-picker';
import Title from '../../shared/components/title';

export const SiteEnquiryFormList = () => {
  const adminSiteEnquiryFormListService = new AdminSiteEnquiryFormListService();

  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));

  const [forms, setForms] = useState([]);
  const [filter, setFilter] = useState('PENDING');
  const [filteredForms, setFilteredForms] = useState();

  useEffect(() => {
    adminSiteEnquiryFormListService.getAllForms()
      .then(response => {
        console.log(response)
        setForms(response)
      })
  }, [])

  useEffect(() => {
    if (forms) {
      if (filter === 'ALL') {
        setFilteredForms([...forms])
      } else if (filter === 'PENDING') {
        setFilteredForms(forms.filter(form => !form.reviewed_by_admin))
      } else {
        setFilteredForms(forms.filter(form => form.reviewed_by_admin))
      }
    }
  }, [filter, forms])

  return (
    <>
      <Toolbar />
      <Box sx={{ m: 4 }}>
        <Paper
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            height: 'auto',
            minHeight: 'calc(100vh - 130px)',
            pt: 3,
            borderRadius: '10px'
          }}
          variant='outlined'
        >
          <Grid container spacing={2} sx={{ mb: 4, alignItems: 'center' }}>
            <Grid item xs={6} md={9} lg={9}>
              <Title>User requests</Title>
            </Grid>
            <Grid item xs={6} md={3} lg={3}>
              <FormControl size="small" sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}>
                <InputLabel id="approval-type-label">Filter</InputLabel>
                <Select
                  labelId="approval-type-label"
                  id="approval-type"
                  value={filter}
                  sx={{ minWidth: '200px' }}
                  label="Filter"
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <MenuItem value={'PENDING'}>Pending</MenuItem>
                  <MenuItem value={'COMPLETED'}>Completed</MenuItem>
                  <MenuItem value={'ALL'}>All</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {filteredForms && filteredForms.map((form, index) => {
            return <Card
              sx={{ alignItems: 'center', justifyContent: 'center', mb: 1, borderRadius: '10px' }}
              key={index}
              variant='outlined'
            >
              <CardActionArea
                sx={{ p: 2 }}
                component={Link}
                to={`/admin/site-enquiry/${form.id}`}
              >
                <List sx={{ p: 0 }}>
                  <ListItem sx={{ p: 0, pt: isTablet ? 4 : 0 }}>
                    <ListItemIcon>
                      <IconPicker icon="pause" color='info' size='medium' />
                    </ListItemIcon>
                    <ListItemText
                      primary={form.email}
                      secondary={<>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Created at:&nbsp;
                        </Typography>
                        {moment(form.created_at).format('DD/MM/yyyy')}
                      </>
                      }
                    />
                    {/* <Box sx={isTablet ? { position: 'absolute', top: 1, right: 1 } : { minWidth: '217px' }}>
                  <Chip
                    edge="end"
                    label={project?.owner?.name}
                    variant='outlined'
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    edge="end"
                    label={project.admin_status.status_name}
                    variant='outlined'
                    color={project.admin_status.status_code === 'DRAFT' ? 'info' : 'primary'}
                  />
                </Box> */}
                  </ListItem>
                </List>
              </CardActionArea>
            </Card>
          })}
          {filteredForms && filteredForms?.length === 0 &&
            <Alert severity="info" sx={{ mb: 4, p: 2 }}>
              There are currently no user request forms.
            </Alert>
          }
        </Paper>
      </Box>
    </>
  );
};

