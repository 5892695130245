import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import { CardActionArea, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import TollIcon from '@mui/icons-material/Toll';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import GrassOutlinedIcon from '@mui/icons-material/GrassOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import WaterIcon from '@mui/icons-material/Water';
import LockIcon from '@mui/icons-material/Lock';
import { HubDetailsContext } from '../context/hub-details';
import { Verified } from '@mui/icons-material';

const ProjectCard = ({
  cardType,
  project,
  id,
  summary,
  primaryImage,
  primaryImageURL,
  location,
  LPA,
  NCA,
  totalBiodiversityUnits,
  totalAreaHabitats,
  totalHedgerowHabitats,
  totalWatercourseHabitats,
  totalPrice,
  hidePrice,
  freeTier,
  shallow_status,
  isMobileMode,
  bngRegistered
}) => {

  const hub = useContext(HubDetailsContext);
  const [routeToProject, setRouteToProject] = useState('projects');

  useEffect(() => {
    // const route = hub.hubDetails.main_hub ? 'projects' : 'hub/projects'
    const route = 'hub/projects'
    setRouteToProject(route)
  }, [hub])

  const [imageLoaded, setImageLoaded] = useState(false);
  const types = [];
  let imagePath;
  if (primaryImage) {
    imagePath = primaryImageURL
  } else {
    imagePath = 'https://ychef.files.bbci.co.uk/976x549/p0b6bt6f.jpg'
  }

  if (totalAreaHabitats > 0) {
    types.push('Area');
  }

  if (totalHedgerowHabitats > 0) {
    types.push('Hedgerow');
  }

  if (totalWatercourseHabitats > 0) {
    types.push('Watercourse');
  }

  if (cardType === 'standard') {
    return (
      <Card
        variant="outlined"
        // sx={{ borderRadius: '10px', minHeight: '100%', maxWidth: isMobileMode ? '310px' : '100%', width: '100%' }}
        sx={{ borderRadius: '10px', minHeight: '100%', maxWidth: '100%', width: '100%' }}
        key={uuidv4()}
      >
        <CardActionArea
          component={Link}
          to={`/${routeToProject}/${id}`}
          state={{ project }}
        >
          <CardMedia
            component="img"
            height="200"
            image={imagePath}
            alt="project"
            key={id}
            onLoad={() => setImageLoaded(true)}
            sx={{ visibility: imageLoaded ? 'visible' : 'hidden', maxHeight: '305px' }}
          />
          <Chip
            label={shallow_status?.status_name || 'Available'}
            variant="outlined"
            sx={{
              position: "absolute",
              top: 10,
              left: 10,
              backgroundColor: '#fff',
              color: shallow_status?.color || '#70942c',
              borderColor: shallow_status?.color || '#70942c'
            }}
          />


          <CardContent sx={{ position: 'relative' }}>
            {bngRegistered && <Tooltip title="BNG registered" placement="right" arrow>
              <Verified
                sx={{
                  color: '#1DA1F2',
                  position: "absolute",
                  top: 10,
                  right: 10,
                }} />
            </Tooltip>}
            <Typography
              variant="h6"
              color="text.primary"
              sx={{
                fontWeight: 600,
                pt: '16px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {summary}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {!freeTier ? (LPA) : (
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Skeleton animation={false} width='90%' height='40px' />
                  <LockIcon />
                </Stack>
                // <span 
                //   style={{
                //     color: 'transparent', 
                //     textShadow: '0 0 8px #000'
                //   }}
                // >
                //   placeholder text
                // </span>
              )}
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
              <TollIcon color="primary" />
              {totalBiodiversityUnits !== 0 && <Typography variant="p" color="text.secondary">
                <span style={{ lineHeight: '24px' }}>
                  <b>Units available: </b> <NumericFormat value={totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                </span>
              </Typography>}
              {totalBiodiversityUnits === 0 && <Typography variant="p" color="text.secondary">
                <span style={{ lineHeight: '24px' }}>
                  <b>Units available: </b>
                  TBC
                </span>
              </Typography>}
            </Stack>
            {/* <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px', alignItems: 'center' }}>
              <SellOutlinedIcon color="primary" />
              {!freeTier ? (
                <Typography variant="p" color="text.secondary">
                  <b>Price: </b>
                  {(!hidePrice && totalPrice !== "0") ? (
                    <NumericFormat value={Math.round(project.lowestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  ) : ("POA")}
                </Typography>
              ) : (
                <>
                  <Typography variant="p" color="text.secondary" sx={{ width: '100%' }}>
                    <b>Unit price: </b>
                  </Typography>
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center', width: '100%' }}>
                    <Skeleton animation={false} width='90%' height='40px' />
                    <LockIcon sx={{ color: '#00000099' }} />
                  </Stack>
                </>
              )}
            </Stack> */}

            <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px', alignItems: 'center' }}>
              <SellOutlinedIcon color="primary" />

              <Typography variant="p" color="text.secondary">
                {(!hidePrice && totalPrice !== "0") && <Stack direction={'row'} alignItems={'center'}>
                  <NumericFormat value={Math.round(project.lowestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                  {project.lowestPriceUnit !== project.highestPriceUnit && <>&nbsp;-&nbsp;</>}
                  {project.lowestPriceUnit !== project.highestPriceUnit && <NumericFormat value={Math.round(project.highestPriceUnit)} displayType={'text'} thousandSeparator={true} prefix={'£'} />}
                </Stack>}

                {(hidePrice || totalPrice === "0") && <Stack direction={'row'} alignItems={'center'}>
                  POA
                </Stack>}

              </Typography>


            </Stack>
            <Divider />
            <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
              {types?.map((type) => {
                let icon;
                switch (type) {
                  case 'Area':
                    icon = <SpaOutlinedIcon />
                    break;
                  case 'Hedgerow':
                    icon = <GrassOutlinedIcon />
                    break;
                  case 'Watercourse':
                    icon = <WaterIcon />
                    break;
                  default:
                    icon = <SpaOutlinedIcon />
                    break;
                }
                return (
                  <Chip
                    label={type}
                    color="primary"
                    variant="outlined"
                    icon={icon}
                    key={uuidv4()}
                  />
                )
              }
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }

  if (cardType === 'wide') {
    return (
      <Card
        variant="outlined"
        sx={{ borderRadius: '10px', height: '100%' }}
        key={uuidv4()}
      >
        <CardActionArea
          component={Link}
          to={`/${routeToProject}/${id}`}
          state={{ project }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4} key={uuidv4()}>
              <CardMedia
                component="img"
                image={imagePath}
                alt="project"
                height="100%"
                key={id}
                onLoad={() => setImageLoaded(true)}
                sx={{ visibility: imageLoaded ? 'visible' : 'hidden', height: '305px', maxHeight: '305px' }}
              />
              <Chip
                label={shallow_status?.status_name || 'Available'}
                variant="outlined"
                //icon={<CircleIcon style={{ fontSize: '6px', color: shallow_status?.color || 'primary' }} />}
                sx={{
                  position: "absolute",
                  top: 25,
                  left: 10,
                  backgroundColor: '#fff',
                  color: shallow_status?.color || '#70942c',
                  borderColor: shallow_status?.color || '#70942c'
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={8} key={uuidv4()}>
              <CardContent>
                {bngRegistered && <Tooltip title="BNG registered" placement="left" arrow sx={{ mb: -2 }}>
                  {/* <Verified
                    sx={{
                      color: '#1DA1F2',
                      position: "absolute",
                      top: 24,
                      right: 10,
                    }} />
                </Tooltip>} */}
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Typography variant="body" color={'#1DA1F2'} sx={{ mr: 1 }}>
                      BNG registered
                    </Typography>

                    <Verified
                      sx={{
                        color: '#1DA1F2',
                      }} />
                  </Stack>
                </Tooltip>}
                <Typography variant="h6" color="text.primary" sx={{
                  fontWeight: 600, pt: '16px', whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}>
                  {summary}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {!freeTier ? (LPA) : (
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                      <Skeleton animation={false} width='90%' height='40px' />
                      <LockIcon />
                    </Stack>
                  )}
                </Typography>
                <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                  <TollIcon color="primary" />
                  {totalBiodiversityUnits !== 0 && <Typography variant="p" color="text.secondary">
                    <span style={{ lineHeight: '24px' }}>
                      <b>Units available: </b>
                      <NumericFormat value={totalBiodiversityUnits} displayType={'text'} thousandSeparator={true} decimalScale={2} />
                    </span>
                  </Typography>}
                  {totalBiodiversityUnits === 0 && <Typography variant="p" color="text.secondary">
                    <span style={{ lineHeight: '24px' }}>
                      <b>Units available: </b>
                      TBC
                    </span>
                  </Typography>}
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px', alignItems: 'center' }}>
                  <SellOutlinedIcon color="primary" />
                  <Typography variant="p" color="text.secondary">
                    <b>Average unit price: </b>
                  </Typography>
                  {!freeTier ? (
                    <Typography variant="p" color="text.secondary">
                      {(!hidePrice && totalPrice !== "0") ? (
                        <NumericFormat value={Math.round(totalPrice / totalBiodiversityUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                      ) : ("POA")}
                    </Typography>
                  ) : (
                    <Stack direction="row" spacing={1} sx={{ alignItems: 'center', width: '100%' }}>
                      <Skeleton animation={false} width='90%' height='40px' />
                      <LockIcon sx={{ color: '#00000099' }} />
                    </Stack>
                  )}
                </Stack>
                <Divider />
                <Stack direction="row" spacing={1} sx={{ mt: '20px', mb: '20px' }}>
                  {types?.map((type) => {
                    let icon;
                    switch (type) {
                      case 'Area':
                        icon = <SpaOutlinedIcon />
                        break;
                      case 'Hedgerow':
                        icon = <GrassOutlinedIcon />
                        break;
                      case 'Watercourse':
                        icon = <WaterIcon />
                        break;
                      default:
                        icon = <SpaOutlinedIcon />
                        break;
                    }
                    return (
                      <Chip
                        label={type}
                        color="primary"
                        variant="outlined"
                        icon={icon}
                        key={uuidv4()}
                      />
                    )
                  }
                  )}
                </Stack>
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    )
  }
};

export default ProjectCard