import { Grid } from '@aws-amplify/ui-react';
import { Alert, IconButton, ImageList, ImageListItem, ImageListItemBar, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from "../../shared/components/file-upload";
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { v4 as uuidv4 } from 'uuid';


export const ImageFileUpload = ({ fileData, setFileData, primaryImageIndex, setPrimaryImageIndex, pdf, downloadableResource, maxSize, imageCountLimit = 10 }) => {

  const storeBlobImages = async file => {
    setFileData([...fileData, file]);
  };

  const getBlobImageUrl = (index) => {
    return URL.createObjectURL(fileData[index]);
  }

  const deleteImage = (index) => {
    setFileData(fileData.filter((file, fileIndex) => fileIndex !== index))
  }

  const handlePrimaryImageClick = (index) => {
    if (setPrimaryImageIndex) {
      setPrimaryImageIndex(index);
    }
  }

  return (
    <Grid item xs={12} md={12} lg={12} sx={{ mt: "16px" }}>
      {fileData.length < imageCountLimit && <FileUpload
        handleFileUpload={storeBlobImages}
        pdf={pdf}
        downloadableResource={downloadableResource}
        maxSize={maxSize}
      />}

      {fileData.length >= imageCountLimit && !pdf && !downloadableResource && <Alert severity="info" sx={{ p: 2 }}>You have reached the maximum amount of allowed images.</Alert>}
      {fileData.length >= imageCountLimit && (pdf || downloadableResource) && <Alert severity="info" sx={{ p: 2 }}>File ready for upload.</Alert>}

      <Grid container='true' spacing={2} sx={{ mt: "16px", width: '100%', marginLeft: '0px' }}>
        <ImageList
          rowHeight={200}
          gap={20}
          cols={3}
        >
          {fileData.length > 0 && !pdf && !downloadableResource && fileData.map((image, index) => {
            return (
              <ImageListItem key={uuidv4()}>
                <img
                  src={getBlobImageUrl(index)}
                  alt='User uploaded content'
                  loading="lazy"
                  style={{ overflow: 'hidden' }}
                />
                <ImageListItemBar
                  actionPosition="left"
                  sx={{
                    background:
                      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                    paddingRight: '0px',
                    width: '100%'
                  }}
                  position="top"
                  className='imagePreview'
                  actionIcon={
                    <>
                      {setPrimaryImageIndex && <Tooltip title="Set as Primary Image" placement="right" arrow='true'>
                        <IconButton
                          sx={{ color: 'white' }}
                          onClick={() => handlePrimaryImageClick(index)}
                        >
                          {(index === primaryImageIndex) ? <StarIcon /> : <StarBorderIcon />}
                        </IconButton>
                      </Tooltip>}
                      <Tooltip title="Delete Image" placement="left" arrow='true'>
                        <IconButton
                          sx={{ color: 'white', float: 'right' }}
                          onClick={() => deleteImage(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </Grid>
    </Grid>
  )
}