import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { ungoalsAutocomplete } from '../../../shared/data/un-goals';
import Html from 'react-pdf-html';

export const InvestmentProjectPdf = ({ projectDetails, hubDetails }) => {

  // const [base64Image, setBase64Image] = useState('')
  const [unGoalsArray, setUnGoalsArray] = useState([]);

  useEffect(() => {
    if (projectDetails?.un_goals) {
      const goals_array = projectDetails?.un_goals.split(',');
      let new_goals_array = [];
      goals_array?.forEach(goal => {
        const goalInfo = ungoalsAutocomplete.filter((obj) => obj.id === Number(goal));
        return new_goals_array?.push(goalInfo[0]);
      });
      setUnGoalsArray(new_goals_array);
    }

  }, [projectDetails])

  const [images, setImages] = useState([]);

  const UNImages = [
    { id: 1, path: `/un-goals/E-WEB-Goal-01.png` },
    { id: 2, path: `/un-goals/E-WEB-Goal-02.png` },
    { id: 3, path: `/un-goals/E-WEB-Goal-03.png` },
    { id: 4, path: `/un-goals/E-WEB-Goal-04.png` },
    { id: 5, path: `/un-goals/E-WEB-Goal-05.png` },
    { id: 6, path: `/un-goals/E-WEB-Goal-06.png` },
    { id: 7, path: `/un-goals/E-WEB-Goal-07.png` },
    { id: 8, path: `/un-goals/E-WEB-Goal-08.png` },
    { id: 9, path: `/un-goals/E-WEB-Goal-09.png` },
    { id: 10, path: `/un-goals/E-WEB-Goal-10.png` },
    { id: 11, path: `/un-goals/E-WEB-Goal-11.png` },
    { id: 12, path: `/un-goals/E-WEB-Goal-12.png` },
    { id: 13, path: `/un-goals/E-WEB-Goal-13.png` },
    { id: 14, path: `/un-goals/E-WEB-Goal-14.png` },
    { id: 15, path: `/un-goals/E-WEB-Goal-15.png` },
    { id: 16, path: `/un-goals/E-WEB-Goal-16.png` },
    { id: 17, path: `/un-goals/E-WEB-Goal-17.png` },
  ];

  useEffect(() => {
    setImages(UNImages);
  }, [projectDetails])

  const styles = StyleSheet.create({
    frontPage: {
      flexDirection: 'column',
      paddingBottom: '24px'
    },
    page: {
      flexDirection: 'column',
      paddingTop: '48px',
      paddingBottom: '48px'
    },
    section: {
      flexGrow: 0,
      paddingTop: '12px',
      paddingLeft: '48px',
      paddingRight: '48px'
    },
    primaryImageBox: {
      width: '100%',
      flexGrow: 0,
    },
    image: {
      maxHeight: '100%',
    },
    imageBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingLeft: '48px',
      paddingRight: '48px'
    },
    additionalImageBox: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      paddingTop: '24px'
    },
    flexImage: {
      maxHeight: '100px',
      marginRight: '8px'
    },
    box: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      padding: '12px',
      alignItems: 'center'
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.frontPage}>
        <View style={styles.box}>
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', marginLeft: '10px', marginRight: '10px' }}>
              Investment Project
            </Text>
          </View>
          <View style={{ height: '100%', flexBasis: '100px' }}>
            <Image style={{ objectFit: 'cover', maxHeight: '200px' }} src={{ uri: hubDetails?.hub_styles.logo_url }}></Image>
          </View>
        </View>
        <View style={styles.primaryImageBox}>
          <Image style={{ objectFit: 'cover', maxHeight: '250px' }} src={{ uri: projectDetails?.investment_project_images[0].location, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}></Image>
        </View>

        <View style={styles.section}>
          <Text style={{ color: '#111E21', fontWeight: '700', marginTop: '30px', fontFamily: 'Helvetica-Bold' }}>
            {projectDetails?.title_text}
          </Text>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica', marginTop: '6px' }}>
            {projectDetails?.subtitle_text}
          </Text>
        </View>

        <View style={styles.section}>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', marginTop: '20px', textDecoration: 'underline' }}>
            Images:
          </Text>
          <View style={styles.additionalImageBox}>
            {projectDetails?.investment_project_images.map((image, index) => {
              return <Image style={{ objectFit: 'cover', maxWidth: '30%', maxHeight: '200px', marginRight: '8px' }} src={{ uri: image.location, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}></Image>
            })}
          </View>
        </View>

        {/* <View style={styles.section}>
          {projectDetails?.external_project_url && <>
            <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', marginTop: '10px' }}>
              External project url:
            </Text>

            <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', marginTop: '10px' }}>
              <a href={projectDetails?.external_project_url} target='_blank' rel="noreferrer">{projectDetails?.external_project_url}</a>
            </Text>
          </>}
        </View> */}

        <View style={styles.section}>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', marginTop: '20px', textDecoration: 'underline' }}>
            Description:
          </Text>

          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica', marginTop: '6px' }}>
            {projectDetails?.description}
          </Text>
        </View>

        {unGoalsArray.length > 0 && <View style={styles.section}>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', textDecoration: 'underline', marginBottom: '10px', marginTop: '20px' }}>
            Related UN SDGs:
          </Text>
        </View>
        }

        <View style={styles.imageBox}>
          {unGoalsArray.length > 0 && unGoalsArray?.map((goal, index) => {
            return <Image style={styles.flexImage} alt='UN Goal' src={images.find(image => image.id === parseInt(goal.id))?.path} />;
          })}
        </View>

      </Page>
      <Page size="A4" style={styles.page}>

        <View style={styles.section}>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', textDecoration: 'underline' }}>
            Public Listing:
          </Text>
          <Html style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica', marginTop: '6px' }}>
            {projectDetails?.admin_display_notes ? projectDetails.admin_display_notes : ''}
          </Html>
        </View>

        <View style={styles.section}>
          <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica-Bold', textDecoration: 'underline', marginTop: '12px' }}>
            Information Provided:
          </Text>
        </View>

        <View style={styles.section}>
          {projectDetails?.answer_forms?.filter(form => form.answers.length > 0).map((form, index) => {
            return <View style={{ marginBottom: '10px' }}>

              {/* <Text style={{ color: '#008938', fontFamily: 'Helvetica-Bold', marginRight: '24px' }}>
                Form name:  {form.question_form?.form_name}
              </Text>

              {form && form.answers.map((answer, index) => {
                return <View key={index}>
                  <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica', marginTop: '10px' }}>
                    {answer?.question_text}
                  </Text>

                  <Text style={{ fontSize: '12px', lineHeight: '1.5', fontFamily: 'Helvetica', marginTop: '10px' }}>
                    {answer?.answer_text}
                  </Text>
                </View>
              }
              )} */}

              <View style={{ backgroundColor: '#0000001f', border: '1px solid #0000001f' }}>
                <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '10px', fontFamily: 'Helvetica-Bold', color: '#111E21' }}>
                  {form.question_form?.form_name}
                </Text>
              </View>

              {form && form.answers.map((answer, index) => {
                return (
                  <View style={{ flexDirection: 'row', border: '1px solid #0000001f' }} key={index}>
                    <View style={{ backgroundColor: '#f5f5f5', flexBasis: '200px', flexDirection: 'column', borderRight: '1px solid #0000001f' }}>
                      <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '10px', fontFamily: 'Helvetica' }}>
                        {answer?.question_text}
                      </Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', flexBasis: '100%' }}>
                      <Text style={{ fontSize: '12px', lineHeight: '1.5', padding: '10px', fontFamily: 'Helvetica' }}>
                        {answer?.answer_text}
                      </Text>
                    </View>
                  </View>
                )
              })}

            </View>
          })}
        </View>
      </Page>
    </Document>
  );
};