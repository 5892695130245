import React, { useState, useEffect, useContext } from "react";
import ArticleService from "../../../shared/services/article-service";
import { useParams } from 'react-router-dom';
import { Box, Container, Grid, InputAdornment, Paper, TextField, Toolbar, Typography } from '@mui/material';
import { GlobalBackButton } from '../../../shared/components/buttons/global-back-button';
import ArticleBodyDisplay from '../../../shared/components/articles/article-body/article-body-display';
import { HubDetailsContext } from '../../../shared/context/hub-details';
import { Search } from '@mui/icons-material';
import httpClient from '../../../shared/http/http-client';

const ArticleDetails = () => {
  const articleService = new ArticleService();
  const params = useParams();
  const hub = useContext(HubDetailsContext);

  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [titleCopy, setTitleCopy] = useState("");
  const [bodyCopy, setBodyCopy] = useState("");
  const [article, setArticle] = useState();

  useEffect(() => {
    articleService.getOneArticle(params.id)
      .then(response => {
        setArticle(response);
      })
  }, []);

  useEffect(() => {
    httpClient.get('data/hub/module-style/learning')
      .then(moduleStyle => {
        if (moduleStyle.data[0].banner_image_url) {
          setBannerImageUrl(moduleStyle.data[0]?.banner_image_url)
        } else {
          setBannerImageUrl(hub.hubDetails.hub_styles.banner_image_url)
        }
        //setTitleCopy(moduleStyle.data[0]?.title_copy ? moduleStyle.data[0].title_copy : "Learning hub");
        //setBodyCopy(moduleStyle.data[0]?.body_copy ? moduleStyle.data[0].body_copy : "Browse learning resources and case studies");
        // setTitleCopy(article?.title_copy || "Learning hub");
        // setBodyCopy(article?.subtitle_copy || "Browse learning resources and case studies");
      })
  }, [hub])

  useEffect(() => {
    window.analytics.page();
  }, []);


  return (
    article && (
      <>
        <Toolbar />
        <Box
          sx={{
            height: '45vh',
            mt: 2,
            backgroundImage: `url(${bannerImageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={2} style={{ height: '45vh', alignItems: 'center' }}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography
                  variant="h3"
                  component="h1"
                  sx={{ mb: 2, textAlign: 'left', fontWeight: 600, color: "common.white" }}
                >
                  {article?.title_copy}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ mb: 2, textAlign: 'left', color: "common.white" }}
                >
                  {article?.subtitle_copy}
                </Typography>
                {/* <TextField
                  id="hub-search"
                  type="text"
                  placeholder="Search"
                  fullWidth
                  sx={{ color: "#fff", backgroundColor: "#fff", borderRadius: '5px' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box sx={{ padding: 3 }}>
          <Container maxWidth="md">
            {/* <GlobalBackButton buttonCopy={'Back to the learning hub'} buttonLink={'/hub/learning'}></GlobalBackButton> */}
            <ArticleBodyDisplay article={article}></ArticleBodyDisplay>
          </Container>
        </Box>
      </>
    )
  )
};

export default ArticleDetails