import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import { UserDetailsContext } from '../../../context/user-details';
import { ListItemText, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
import SellIcon from '@mui/icons-material/Sell';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { HubDetailsContext } from '../../../context/hub-details';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PersonIcon from '@mui/icons-material/Person';
import { AssistantPhoto, ChatRounded, HelpCenterRounded, NewReleasesOutlined } from '@mui/icons-material';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      backgroundColor: '#ffffff',
      color: "#70942c",
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        backgroundColor: '#ffffff',
        color: "#70942c",
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: "56px",
        [theme.breakpoints.up('sm')]: {
          width: "56px",
        },
      }),
    },
  }),
);

const LeftNav = ({ isAdminPage = false, setLeftNavExpanded }) => {
  const [open, setOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [moduleCodes, setModuleCodes] = useState([]);
  const [hideNav, setHideNav] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
    setLeftNavExpanded(!open);
  };

  const user = useContext(UserDetailsContext);
  const hubContext = useContext(HubDetailsContext);

  useEffect(() => {
    if (user.userDetails) {
      setIsAdmin(user.userDetails.isAdmin);
      setIsSuperAdmin(user.userDetails.isSuperAdmin ? true : false);
      setHideNav(user.userDetails.userInformationRequired || (user.userDetails.roles.includes('ECOLOGIST') && !user.userDetails.roles.includes('SELLER')));
      // if (!activeRole) {
      //   setActiveRole(user?.userDetails?.isAdmin ? 'Admin' : 'Seller');
      // }
    }
  }, [user.change, user])

  useEffect(() => {
    if (hubContext.hubDetails) {
      setModuleCodes([]);
      hubContext.hubDetails.hubTiles?.forEach(tile => {
        const codes = moduleCodes;
        codes.push(tile?.tile_code);
        setModuleCodes(codes);
      })
    }
  }, [hubContext.hubDetails])

  return (<>
    {!hideNav && <Drawer
      variant="permanent"
      open={open}
      sx={{ height: '100vh' }}
    >
      <>
        <List component="nav" sx={{ minHeight: '64px' }}>
          {/* <Link onClick={toggleDrawer}> */}
          <ListItemButton color="primary" sx={{ mt: '4px' }} disableRipple onClick={toggleDrawer}>
            <img
              //src='/gaia_icon.png'
              src={isAdminPage ? '/gaia_icon.png' : '/gaia_icon_grey.png'}
              width='18px'
              height='18px'
              style={{ margin: '3px' }}
              alt='Gaia logo'
            />
          </ListItemButton>
          {/* </Link> */}
        </List>
        <Divider />
        {!isAdminPage && <>
          <List component="nav" sx={{ flex: 1 }}>
            <Link to="/dashboard">
              <Tooltip title="Dashboard" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <DashboardIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            {moduleCodes.includes('BNG_MARKETPLACE') && <Link to="/projects">
              <Tooltip title="My Listings" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon color='primary'>
                    <ViewListIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="My Listings" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            }
            {moduleCodes.includes('INVESTMENT_PROJECT') && <Link to="/investment-projects">
              <Tooltip title="My Investment Projects" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <WysiwygIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Investment Projects" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            }
            {moduleCodes.includes('BNG_MARKETPLACE') && <Link to="/deals">
              <Tooltip title="Enquiries" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <ChatRounded color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Enquiries" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            }
            {moduleCodes.includes('BNG_MARKETPLACE') &&
              <Link to="/sales">
                <Tooltip title="Sales" placement="right">
                  <ListItemButton disableRipple>
                    <ListItemIcon >
                      <SellIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary="Sales" primaryTypographyProps={{ color: 'text.secondary' }} />
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
          </List>
        </>}
        {isAdmin && isAdminPage && <>
          <List component="nav" sx={{ flex: 1 }}>
            {moduleCodes.includes('BNG_MARKETPLACE') &&
              <Link to="/admin/projects">
                <Tooltip title="All Projects" placement="right">
                  <ListItemButton disableRipple>
                    <ListItemIcon >
                      <ViewListIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary="All Projects" primaryTypographyProps={{ color: 'text.secondary' }} />
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
            {moduleCodes.includes('INVESTMENT_PROJECT') &&
              <Link to="/admin/investment-projects">
                <Tooltip title="All Investment Projects" placement="right">
                  <ListItemButton disableRipple>
                    <ListItemIcon >
                      <WysiwygIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary="All Investment Projects" primaryTypographyProps={{ color: 'text.secondary' }} />
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
            {moduleCodes.includes('LEARNING_HUB') &&
              <Link to="/admin/articles">
                <Tooltip title="Articles" placement="right">
                  <ListItemButton disableRipple>
                    <ListItemIcon >
                      <ArticleIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary="Articles" primaryTypographyProps={{ color: 'text.secondary' }} />
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
            <Link to="/admin/approvals">
              <Tooltip title="Approvals" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <ThumbUpAltIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Approvals" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            <Link to="/admin/users">
              <Tooltip title="Users" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <PersonIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Users" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            <Link to="/admin/enquiries">
              <Tooltip title="Enquiries" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <ChatRounded color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Enquiries" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            <Link to="/admin/site-enquiries">
              <Tooltip title="User requests" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <AssistantPhoto color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="User Requests" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            <Link to="/admin/configuration/branding">
              <Tooltip title="Configuration" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <SettingsIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Configuration" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
          </List>
        </>
        }
        {isAdmin && <>
          <List component="nav" sx={{ mb: 2 }}>
            {isSuperAdmin && isAdminPage && <Link to="/super-admin/dashboard">
              <Tooltip title="Gaia Admin" placement="right">
                <ListItemButton disableRipple>
                  <ListItemIcon >
                    <AdminPanelSettingsIcon color='primary' />
                  </ListItemIcon>
                  <ListItemText primary="Gaia Admin" primaryTypographyProps={{ color: 'text.secondary' }} />
                </ListItemButton>
              </Tooltip>
            </Link>
            }
            {!isAdminPage &&
              <Link to={'/admin/dashboard'}>
                <Tooltip title="Switch to Admin" placement="right">
                  <ListItemButton color="primary" sx={{ mt: '4px' }} disableRipple>
                    <ListItemIcon >
                      <SwapHorizIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary="Switch to Admin" primaryTypographyProps={{ color: 'text.secondary' }} />
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
            {isAdminPage &&
              <Link to={'/dashboard'}>
                <Tooltip title="Switch to Seller" placement="right">
                  <ListItemButton color="primary" sx={{ mt: '4px' }} disableRipple>
                    <ListItemIcon >
                      <SwapHorizIcon color='primary' />
                    </ListItemIcon>
                    <ListItemText primary="Switch to Seller" primaryTypographyProps={{ color: 'text.secondary' }} />
                  </ListItemButton>
                </Tooltip>
              </Link>
            }
          </List>
        </>}
      </>
    </Drawer >}
  </>
  )
}

export default LeftNav;