import React, { useEffect, useState } from "react";
import { Container, Grid, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Title from '../../shared/components/title';
import UsersList from '../components/users-list';

const AdminUsers = () => {
  const [userType, setUserType] = useState('SELLER');

  useEffect(() => {
    window.analytics.page();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                minHeight: 'calc(100vh - 130px)',
                pt: 3,
                borderRadius: '10px'
              }}
              variant='outlined'
            >
              <Grid container spacing={2} sx={{ mb: 2, alignItems: 'center' }}>
                <Grid item xs={12} md={9} lg={9}>
                  <Title>Users</Title>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <FormControl size="small" sx={{ justifyContent: "flex-end", ml: "auto", mt: "15px", float: "right" }}>
                    <InputLabel id="user-type-label">User Type</InputLabel>
                    <Select
                      labelId="user-type-label"
                      id="user-type"
                      value={userType}
                      label="User Type"
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <MenuItem value={'SELLER'}>Sellers</MenuItem>
                      <MenuItem value={'BUYER'}>Buyers</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <UsersList userRoleFilter={userType} />
            </Paper>
          </Grid >
        </Grid >
      </Container >
    </>
  );
};

export default AdminUsers 