import { GrassOutlined, SpaOutlined, Verified, Water } from '@mui/icons-material'
import Lock from '@mui/icons-material/Lock'
import { Alert, Box, Chip, Divider, Grid, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { BngProjectDisplayNotes } from './bng-project-display-notes'
import gaiaTheme from '../../theme'

const ProjectDetailSummary = ({ enquirySent, freeTier, projectDetails, groupAtProjectlevel, isMainHub }) => {
  const isTablet = useMediaQuery(gaiaTheme.breakpoints.down('md'));
  const isMobile = useMediaQuery(gaiaTheme.breakpoints.down('sm'));

  return (
    <>
      {projectDetails && <Box sx={{ mt: 4 }}>
        <Grid container spacing={2} sx={{ pl: '6px' }} flexDirection={isTablet ? 'column-reverse' : 'row'}>

          {enquirySent === true && <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Alert severity="success">Enquiry Sent</Alert>
          </Grid>}

          <Grid item xs={12} md={9} lg={9}>
            <Box>
              <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                {!freeTier ? (isMainHub ? projectDetails.p_local_planning_authority : projectDetails.p_summary) : (
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Skeleton animation={false} width='50%' height='40px' />
                    <Lock sx={{ color: '#00000099' }} />
                  </Stack>
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body" color="text.primary" sx={{ mb: '20px', whiteSpace: 'pre-wrap' }}>
                {!freeTier ? (isMainHub ? projectDetails.p_summary : projectDetails?.custom_region_tag) : (
                  <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                    <Skeleton animation={false} width='30%' height='40px' />
                    <Lock sx={{ color: '#00000099' }} />
                  </Stack>
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={3} lg={3}>
            {projectDetails.bng_gov_registered && <Stack direction={'row'} justifyContent={'flex-end'}>
              <Typography variant="body" color={'#1DA1F2'} sx={{ mr: 1 }}>
                BNG registered
              </Typography>
              <Tooltip title="BNG registered" placement="right" arrow>
                <Verified
                  sx={{
                    color: '#1DA1F2',
                  }} />
              </Tooltip>
            </Stack>}

            <Typography variant="body" color="text.secondary" sx={{ justifyContent: "flex-end", ml: "auto", float: "right" }}>
              Added on {projectDetails.dateAdded}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: '6px' }}>
          <Grid item xs={12} md={12} lg={12} sx={{ pb: 2 }}>
            <Box>
              <Divider sx={{ mt: '34px', mb: '50px' }} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="h5" color="text.primary" sx={{ fontWeight: 600 }}>
                    Overview
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary">
                      <b style={{ marginRight: '10px' }}>Description</b>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary" sx={{ whiteSpace: 'pre-wrap' }}>
                      {!freeTier ? (projectDetails.p_description) : (
                        <Lock sx={{ color: '#00000099' }} />
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary">
                      <b style={{ marginRight: '10px' }}>Local Planning Authority</b>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary">
                      {!freeTier ? (projectDetails.p_local_planning_authority) : (
                        <Lock sx={{ color: '#00000099' }} />
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary">
                      <b style={{ marginRight: '10px' }}>National Character Area</b>
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <Typography variant="subtitle1" color="text.primary">
                      {!freeTier ? (projectDetails.p_national_character_area) : (
                        <Lock sx={{ color: '#00000099' }} />
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                {projectDetails.totalArea > 0 && (
                  <>
                    <Grid item xs={12} md={4} lg={3} >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Size</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9} >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          {!freeTier ? (`${projectDetails.totalArea} hectares`) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </>
                )}
                {!projectDetails.unit_project && <>
                  <Grid item xs={12} md={4} lg={3} >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>Total Units</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9} >
                    <Stack spacing={2} direction="row" alignItems="center">
                      {projectDetails.totalBiodiversityUnitsRemaining > 0 && <Typography variant="subtitle1" color="text.primary">
                        <NumericFormat value={projectDetails.totalBiodiversityUnitsRemaining} displayType={'text'} thousandSeparator={true} decimalScale={2} /> units
                      </Typography>}
                      {projectDetails.totalBiodiversityUnitsRemaining === 0 && <Typography variant="subtitle1" color="text.primary">
                        TBC
                      </Typography>}
                    </Stack>
                  </Grid>
                  {groupAtProjectlevel && <>
                    <Grid item xs={12} md={4} lg={3} >
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <b style={{ marginRight: '10px' }}>Total Hectares</b>
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8} lg={9}>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography variant="subtitle1" color="text.primary">
                          <NumericFormat value={Math.round(projectDetails.totalHectaresAvailable)} displayType={'text'} thousandSeparator={true} />
                        </Typography>
                      </Stack>
                    </Grid>
                  </>}
                  <Grid item xs={12} md={4} lg={3}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>Unit Price</b>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8} lg={9}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      {!freeTier ? (<Typography variant="subtitle1" color="text.primary">
                        {(!projectDetails.hidePrice && projectDetails.totalPrice !== "0") ? (
                          <NumericFormat value={Math.round(projectDetails.totalPrice / projectDetails.totalBiodiversityUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                        ) : ("POA")}
                      </Typography>) : (
                        <Lock sx={{ color: '#00000099' }} />
                      )}

                    </Stack>
                  </Grid>
                </>}

                {projectDetails.unit_project && <>
                  <Grid item xs={12} md={4} lg={3} >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <b style={{ marginRight: '10px' }}>Price</b>
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={8} lg={9}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography variant="subtitle1" color="text.primary">
                        <NumericFormat value={Math.round(projectDetails.unit_project.price)} displayType={'text'} thousandSeparator={true} prefix={'£'} /> per {projectDetails.unit_project.price_unit}
                      </Typography>
                    </Stack>
                  </Grid>

                </>}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        {projectDetails?.admin_display_notes && <Divider sx={{ mt: 6, mb: 6 }} />}

        <BngProjectDisplayNotes isAdmin={false} project={projectDetails} />

        {(projectDetails.totalAreaHabitats) > 0 && (
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              mt: '40px'
            }}
            variant='outlined'
          >
            <Grid container spacing={2}>
              <Grid item xs={9} md={9} lg={9}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                  <SpaOutlined color="primary" />
                  <Typography vvariant="subtitle1" color="text.primary">
                    <b>Area Units</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography vvariant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                  <b>
                    {projectDetails.project_area_habitats.every(area => !area.pah_hide_price) ? (
                      <NumericFormat value={Math.round(projectDetails.totalAreaPrice / projectDetails.totalAreaUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    ) : ("POA")}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '32px' }} />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Broad Habitat</TableCell>
                      <TableCell>Habitat</TableCell>
                      {!groupAtProjectlevel && <TableCell align="right">Area (hectares)</TableCell>}
                      <TableCell align="right">Available Units</TableCell>
                      <TableCell align="right">Unit Price (£)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.project_area_habitats.map((areaHabitat, index) => (
                      <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">{areaHabitat.pah_broad_habitat}</TableCell>
                        <TableCell>
                          {!freeTier ? (areaHabitat?.pah_habitat) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        {!groupAtProjectlevel && <TableCell align="right">
                          {!freeTier ? (areaHabitat.pah_area) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>}
                        <TableCell align="right">
                          {areaHabitat.biodiversity_units_remaining === 0 ? 'TBC' : !freeTier ? (areaHabitat.biodiversity_units_remaining) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (
                            !areaHabitat.pah_hide_price ? (
                              <NumericFormat value={Math.round(areaHabitat.pah_total_price / areaHabitat.pah_biodiversity_units)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            ) : ("POA")
                          ) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true)?.length > 0 && (
                <>
                  <Typography variant="subtitle1" color="primary" sx={{ pb: 1, mt: 4 }}>
                    <b>Units closed / deleted</b>
                  </Typography>
                  <TableContainer>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pl: 0 }}>Status</TableCell>
                          <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                          <TableCell>Habitat</TableCell>
                          <TableCell align="right">Area (hectares)</TableCell>
                          <TableCell align="right">Units Remaining</TableCell>
                          <TableCell align="right">Units Sold</TableCell>
                          <TableCell align="right">Unit Price (£)</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true).map((filteredAreaHabitat, index) => (
                          <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              <Chip
                                edge="end"
                                label={filteredAreaHabitat.deleted_at ? 'Deleted' : 'Closed'}
                                variant='outlined'
                                color={filteredAreaHabitat.deleted_at ? 'error' : 'info'}
                                sx={{ mr: 1 }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              {filteredAreaHabitat.pah_broad_habitat}
                            </TableCell>
                            <TableCell>{filteredAreaHabitat.pah_habitat}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.pah_area}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.biodiversity_units_remaining
                            }</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.units_sold}</TableCell>
                            <TableCell align="right">
                              <NumericFormat value={filteredAreaHabitat.pah_total_price / filteredAreaHabitat.pah_biodiversity_units} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                              </Stack>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Paper>
        )}
        {(projectDetails.totalHedgerowHabitats > 0) && (
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              mt: '15px'
            }}
            variant='outlined'
          >
            <Grid container spacing={2}>
              <Grid item xs={9} md={9} lg={9}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                  <GrassOutlined color="primary" />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Hedgerow Units</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                  <b>
                    {projectDetails.project_hedgerow_habitats.every(area => !area.phh_hide_price) ? (
                      <NumericFormat value={Math.round(projectDetails.totalHedgerowPrice / projectDetails.totalHedgerowUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    ) : ("POA")}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '32px' }} />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Broad Habitat</TableCell>
                      <TableCell>Habitat</TableCell>
                      <TableCell align="right">Length (km)</TableCell>
                      <TableCell align="right">Available Units</TableCell>
                      <TableCell align="right">Unit Price (£)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.project_hedgerow_habitats.map((hedgerowHabitat, indexx) => (
                      <TableRow key={indexx} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {hedgerowHabitat.phh_broad_habitat}
                        </TableCell>
                        <TableCell>
                          {!freeTier ? (hedgerowHabitat?.phh_habitat) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (hedgerowHabitat.phh_area) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (hedgerowHabitat.biodiversity_units_remaining) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (
                            !hedgerowHabitat.phh_hide_price ? (
                              <NumericFormat value={Math.round(hedgerowHabitat.phh_total_price / hedgerowHabitat.phh_biodiversity_units)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            ) : ("POA")
                          ) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {projectDetails.project_hedgerow_habitats.filter(areaHabitat => areaHabitat.pah_closed_status === true)?.length > 0 && (
                <>
                  <Typography variant="subtitle1" color="primary" sx={{ pb: 1, mt: 4 }}>
                    <b>Units closed / deleted</b>
                  </Typography>
                  <TableContainer>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pl: 0 }}>Status</TableCell>
                          <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                          <TableCell>Habitat</TableCell>
                          <TableCell align="right">Area (hectares)</TableCell>
                          <TableCell align="right">Units Remaining</TableCell>
                          <TableCell align="right">Units Sold</TableCell>
                          <TableCell align="right">Unit Price (£)</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.project_area_habitats.filter(areaHabitat => areaHabitat.phh_closed_status === true).map((filteredAreaHabitat, index) => (
                          <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              <Chip
                                edge="end"
                                label={filteredAreaHabitat.deleted_at ? 'Deleted' : 'Closed'}
                                variant='outlined'
                                color={filteredAreaHabitat.deleted_at ? 'error' : 'info'}
                                sx={{ mr: 1 }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              {filteredAreaHabitat.phh_broad_habitat}
                            </TableCell>
                            <TableCell>{filteredAreaHabitat.phh_habitat}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.phh_area}</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.biodiversity_units_remaining
                            }</TableCell>
                            <TableCell align="right">{filteredAreaHabitat.units_sold}</TableCell>
                            <TableCell align="right">
                              <NumericFormat value={filteredAreaHabitat.phh_total_price / filteredAreaHabitat.phh_biodiversity_units} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                              </Stack>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Paper>
        )}
        {(projectDetails.totalWatercourseHabitats > 0) && (
          <Paper
            sx={{
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
              mt: '15px'
            }}
            variant='outlined'
          >
            <Grid container spacing={2}>
              <Grid item xs={9} md={9} lg={9}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ mb: '32px' }}>
                  <Water color="primary" />
                  <Typography variant="subtitle1" color="text.primary">
                    <b>Watercourse Units</b>
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3} md={3} lg={3}>
                <Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "right" }}>
                  <b>
                    {projectDetails.project_watercourse_habitats.every(area => !area.pwh_hide_price) ? (
                      <NumericFormat value={Math.round(projectDetails.totalWatercoursePrice / projectDetails.totalWatercourseUnits)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                    ) : ("POA")}
                  </b>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mb: '32px' }} />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <TableContainer>
                <Table aria-label="caption table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Broad Habitat</TableCell>
                      <TableCell>Habitat</TableCell>
                      <TableCell align="right">Length (km)</TableCell>
                      <TableCell align="right">Available Units</TableCell>
                      <TableCell align="right">Unit Price (£)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectDetails.project_watercourse_habitats.map((watercourseHabitat, index) => (
                      <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          {watercourseHabitat.pwh_broad_habitat}
                        </TableCell>
                        <TableCell>
                          {!freeTier ? (watercourseHabitat?.pwh_habitat) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (watercourseHabitat.pwh_area) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (watercourseHabitat.biodiversity_units_remaining) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {!freeTier ? (
                            !watercourseHabitat.pwh_hide_price ? (
                              <NumericFormat value={Math.round(watercourseHabitat.pwh_total_price / watercourseHabitat.pwh_biodiversity_units)} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            ) : ("POA")
                          ) : (
                            <Lock sx={{ color: '#00000099' }} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).length > 0 && (
                <>
                  <Typography variant="subtitle1" color="primary" sx={{ pb: 1, mt: 4 }}>
                    <b>Units closed / deleted</b>
                  </Typography>
                  <TableContainer>
                    <Table aria-label="caption table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ pl: 0 }}>Status</TableCell>
                          <TableCell sx={{ pl: 0 }}>Broad Habitat</TableCell>
                          <TableCell>Habitat</TableCell>
                          <TableCell align="right">Length (km)</TableCell>
                          <TableCell align="right">Units Remaining</TableCell>
                          <TableCell align="right">Units Sold</TableCell>
                          <TableCell align="right">Price (£)</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {projectDetails.project_watercourse_habitats.filter(watercourseHabitat => watercourseHabitat.pwh_closed_status === true).map((filteredWatercourseHabitat, index) => (
                          <TableRow key={index} hover sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              <Chip
                                edge="end"
                                label={filteredWatercourseHabitat.deleted_at ? 'Deleted' : 'Closed'}
                                variant='outlined'
                                color={filteredWatercourseHabitat.deleted_at ? 'error' : 'info'}
                                sx={{ mr: 1 }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                              {filteredWatercourseHabitat.pwh_broad_habitat}
                            </TableCell>
                            <TableCell>{filteredWatercourseHabitat.pwh_habitat}</TableCell>
                            <TableCell align="right">{filteredWatercourseHabitat.pwh_area}</TableCell>
                            <TableCell align="right">{filteredWatercourseHabitat.biodiversity_units_remaining
                            }</TableCell>
                            <TableCell align="right">{filteredWatercourseHabitat.units_sold}</TableCell>
                            <TableCell align="right">
                              <NumericFormat value={filteredWatercourseHabitat.pwh_total_price} displayType={'text'} thousandSeparator={true} prefix={'£'} />
                            </TableCell>
                            <TableCell align="right">
                              <Stack direction="row" spacing={0} sx={{ justifyContent: "flex-end" }}>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </Grid>
          </Paper>
        )}
      </Box>}
    </>
  )
}

export default ProjectDetailSummary