import React, { useEffect, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Alert, Box, Chip, Stack } from "@mui/material";
import ProjectService from '../../services/project-service';
import Title from '../../../shared/components/title';
import SellerInvestmentProjectService from '../../services/seller-investment-project-service';
import moment from 'moment';

export default function DashboardInvestmentProjectsTable() {

  const projectService = new SellerInvestmentProjectService();

  const [investmentProjectsLoading, setInvestmentProjectsLoading] = useState(0);
  const [investmentProjects, setInvestmentProjects] = useState([]);
  const [noProjects, setNoProjects] = useState(false);
  const [projectStatusFilter, setProjectStatusFilter] = useState('ALL');

  const navigate = useNavigate();

  const setSelectedRow = (project) => {
    navigate(`/investment-project/${project.id}`, {
      state: { project }
    })
  }

  const getProjects = async () => {
    setInvestmentProjectsLoading(true);
    let params = new URLSearchParams();
    params.append('page', 0);
    params.append('size', 5);
    projectService.getAllInvestmentProjects(params)
      .then(response => {
        if (response.investmentProjects.length === 0) {
          setNoProjects(true);
        } else {
          setInvestmentProjects(response.investmentProjects);
        }
        setInvestmentProjectsLoading(false);
      })
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      {noProjects && (
        <Alert severity="info" sx={{ p: 2, m: 8 }}>No listings have been created yet.</Alert>
      )}
      {!noProjects && (<>

        <Title>Investment Projects</Title>
        <Stack direction='column' sx={{ justifyContent: 'space-between', height: '100%' }}>
          <Box sx={{
            maxWidth: '100%',
            overflow: 'auto'
          }}>
            <Table>
              <TableHead>
                <TableRow sx={{ fontWeight: 600 }}>
                  <TableCell sx={{ fontWeight: 600, pl: 0 }}>Title</TableCell>
                  <TableCell align='right' sx={{ fontWeight: 600 }}>Status</TableCell>
                  <TableCell align='right' sx={{ fontWeight: 600 }}>Requirement</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(investmentProjectsLoading ? Array.from(new Array(10)) : investmentProjects)?.slice(0, 3).map((investmentProject, index) => (
                  investmentProject ? (
                    <TableRow key={investmentProject.id} onClick={() => setSelectedRow(investmentProject)} hover={true}>
                      <TableCell sx={{ pl: 0 }}>{investmentProject.title_text}</TableCell>
                      <TableCell align='right'>
                        <Chip
                          label={investmentProject.admin_status.status_name}
                          variant="outlined"
                          sx={{
                            justifyContent: "center",
                            color: investmentProject.admin_status.color || "primary",
                            borderColor: investmentProject.admin_status.color || "primary"
                          }}
                        />
                      </TableCell>
                      <TableCell align='right'><NumericFormat value={investmentProject.investment_target} displayType={'text'} thousandSeparator={true} decimalScale={2} prefix={'£'} /></TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" sx={{ pl: 0 }}>
                        <Skeleton animation="wave" variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="text" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" variant="text" />
                      </TableCell>
                    </TableRow>
                  )))
                }
              </TableBody>
            </Table>
          </Box>
          <Link color="primary" to="/investment-projects" sx={{ mt: 3 }} component={RouterLink} >
            See all projects
          </Link>
        </Stack>
      </>
      )}
    </>
  );
}
