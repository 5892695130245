import { DeleteForeverOutlined } from '@mui/icons-material'
import { Button, Chip, Stack, Typography } from '@mui/material'
import ProjectResourceService from '../../services/project-resource-service'

export const ProjectResouceList = ({ resources, onSuccess, hideTitle }) => {

  const projectResourceService = new ProjectResourceService();

  const deleteProjectResource = (id) => {
    projectResourceService.deleteResource(id)
      .then(() => {
        onSuccess()
      })
  }

  return (
    <Stack direction={'column'} alignItems={!hideTitle ? 'center' : 'flex-start'}>
      {!hideTitle && <Typography variant="body" color="text.primary" sx={{ mb: 1, textAlign: 'center' }}>
        Resources added:
      </Typography>}
      {resources && resources.map((resource, index) => {
        return <Stack direction={'row'} alignItems={'center'} sx={{ mb: 2 }} key={index}>
          <Chip
            edge="end"
            label={resource.resource_name}
            variant='outlined'
            color={'info'}
            sx={{ mr: 1 }}
          />

          <Button
            variant='outlined'
            onClick={() => deleteProjectResource(resource.id)}
            disableElevation
            color='error'
            className='removeUppercase'>
            <DeleteForeverOutlined />
          </Button>
        </Stack>
      })}
    </Stack>

  )
}