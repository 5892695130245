import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import Skeleton from '@mui/material/Skeleton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import AdminEnquiriesService from '../services/admin-enquiries-service';
import { Box, Container, Paper, TableHead, Toolbar, Typography } from '@mui/material';
import moment from 'moment';
import Title from '../../shared/components/title';

export const AdminEnquiries = () => {
  const navigate = useNavigate();
  const adminEnquiriesService = new AdminEnquiriesService();

  const [enquiries, setEnquiries] = useState();
  const [projectType, setProjectType] = useState('BNG_PROJECT');

  const setSelectedRow = (enquiry) => {
    navigate(`/admin/enquiry/${enquiry.e_id}`)
  }

  const getEnquiries = (projectType) => {
    adminEnquiriesService.getEnquiries(projectType)
      .then(response => {
        setEnquiries(response)
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    getEnquiries(projectType);
  }, [projectType])

  return (<>
    <Toolbar />
    <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          minHeight: 'calc(100vh - 130px)',
          borderRadius: '10px'
        }}
        variant='outlined'
      >
        <Title sx={{ mb: 4 }}>Enquiries</Title>
        {!enquiries && <Table>
          <TableBody>
            {Array.from(new Array(20)).map((enquiry, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
                <TableCell>
                  <Skeleton animation="wave" variant="text" />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
        {enquiries?.length === 0 && (
          <Alert severity="info" sx={{ p: 2, m: 8 }}>No enquiries have been received yet.</Alert>
        )}
        {enquiries?.length > 0 && (
          <Box sx={{
            maxWidth: '100%',
            overflow: 'auto'
          }}>
            <Table>
              <TableHead>
                <TableRow sx={{ fontWeight: 600 }}>
                  <TableCell sx={{ fontWeight: 600 }}>Created</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Project type</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Project ID</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>Project title</TableCell>
                  <TableCell sx={{ fontWeight: 600 }}>LPA / subtitle</TableCell>
                  {/* <TableCell sx={{ fontWeight: 600 }}>Last update</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiries && enquiries.map((enquiry, index) => {
                  return <TableRow key={index} onClick={() => setSelectedRow(enquiry)} hover={true}>
                    <TableCell>{moment(enquiry.created_at).format('ddd/MM/yyyy')}<br></br> <Typography fontWeight={600} color={'primary'} variant='p'>{moment(enquiry.updated_at).fromNow(true)} ago</Typography></TableCell>
                    <TableCell>{enquiry.project_p_id ? 'BNG Project' : 'Investment Project'}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.project.p_id : enquiry.investment_project.id}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.project.p_summary : enquiry.investment_project?.title_text}</TableCell>
                    <TableCell>{enquiry.project_p_id ? enquiry.project.p_local_planning_authority : enquiry.investment_project?.subtitle_text}</TableCell>
                    {/* <TableCell>{moment(enquiry.updated_at).fromNow(true)} ago</TableCell> */}
                  </TableRow>
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Paper>
    </Container>
  </>
  );
}
